/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Raleway-Bold';
  src: url('fonts/Raleway-Bold.ttf');
}

.font-ralewayBold {
  font-family: 'Raleway-Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Raleway-ExtraBold';
  src: url('fonts/Raleway-ExtraBold.ttf');
}

.font-ralewayExtraBold {
  font-family: 'Raleway-ExtraBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Raleway-Regular';
  src: url('fonts/Raleway-Regular.ttf');
}

.font-ralewayRegular {
  font-family: 'Raleway-Regular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('fonts/Raleway-SemiBold.ttf');
}

.font-ralewaySemiBold {
  font-family: 'Raleway-SemiBold', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: '.AppleSystemUIFont';
  src: url('fonts/SFNS.ttf');
}

.font-AppleSystemUIFont {
  font-family: '.AppleSystemUIFont', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.App { /* Base font style */
  font-family: 'Raleway-Regular', sans-serif;
  font-size: 14.0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: 'Raleway-Regular', sans-serif;
  font-size: 14.0px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: #7f7f7f;
}

.App .secondaryTextBg {
  background-color: #7f7f7f;
}

.App .primaryTextColor {
  color: #feffff;
}

.App .primaryBg {
  background-color: #feffff;
}

.App .highlightTextColor {
  color: #7f7eff;
}

.App .highlightBg {
  background-color: #7f7eff;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: 14.0px;
  font-weight: 600;
}
.App .actionFont * {
  font-size: 14.0px;
}
.App .headlineFont {
  font-family: 'Raleway-Bold', sans-serif;
  font-size: 20.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 20.0px;
}
.App .navBar {
  background-color: #feffff;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: 'Raleway-Regular', sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

.App {
  --tab-bar-height: 54px;
}
@media (display-mode: standalone) {
  .App {
    --tab-bar-height: 90px;
  }
}
.App .tabBar {
  height: var(--tab-bar-height);
  display: flex;
  flex-direction: row;
  pointer-events: auto;
  z-index: 9999;
}
.App .tabBar > * {
  flex: 1 0 auto;
  text-align: center;
  cursor: pointer;
}
.App .tabBar div {
  box-sizing: border-box;
  font-size: 13px; /* default size for tab bar text */
  line-height: 15px;
}
@media (max-width: 767px) {
  .App .tabBar div {
    font-size: 11px; /* small screen size for tab bar text */
    line-height: 13px;
  }
}
.App .tabBar img {
  height: 24px;
  padding-top: 6px;
}
.App .tabBarMenu {
  overflow: hidden;
}
.App .tabBarPageContent {
  overflow: scroll;
  flex: 1;
}
.App .tabBarPageContent > .AppScreen {
  position: relative;
  overflow: scroll;
}
.App .tabBarPageContent > .AppScreen > .background {
  position: fixed;
  left: auto;
  top: auto;
  height: calc(100% - var(--tab-bar-height));
}
.App .tabBarPageContent > .AppScreen > .navBarContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App .tabBarPageContent > .AppScreen > .navBarContainer > .navBar {
  position: fixed;
  left: auto;
  top: auto;
}
@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .Footer {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Footer > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Footer > .layoutFlow > .flowRow > .elPolicy {
    line-height: 18px;
    position: absolute;
    margin-top: 30px;
    width: 100%;
  }
  .Footer > .layoutFlow > .flowRow > .elPolicy > * { 
  margin-left: auto;
  width: 172px;
  margin-right: 50.8%;
  }
  
  .flowRow_Footer_elPolicy_203581 { 
  }
  


  .Footer > .layoutFlow > .flowRow > .elTerms {
    line-height: 18px;
    position: absolute;
    top: 30px;
    width: 170px;
    margin-left: calc(50.0% + 5px);
  }
  .Footer > .layoutFlow > .flowRow > .elTerms > * { 
  width: 170px;
  }
  


  .Footer > .layoutFlow > .elText2 {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Footer > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Selector {
    min-height: 23px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 110px */
    height: 100%;
  }
  .Selector > .background > .containerMinHeight {
    min-height: 23px;
  }


  .Selector > .foreground > .state0_elLanguage {
    overflow: hidden;
    position: absolute;
    right: 2px;
    top: 0px;
    width: 80px;
    height: 33px;
  }


  .Selector > .foreground > .state0_elEarthglobe466375 {
    position: absolute;
    right: 88px;
    top: 1px;
    width: 18px;
    height: 18px;
  }

/* This component has a fixed-size layout */
  .Selector {
    min-height: 23px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 110px */
    height: 100%;
  }
  .Selector > .background > .containerMinHeight {
    min-height: 23px;
  }


  .Selector > .foreground > .state1_elLanguage {
    overflow: hidden;
    position: absolute;
    right: 2px;
    top: 0px;
    width: 80px;
    height: 33px;
  }


  .Selector > .foreground > .state1_elEarthglobe466375 {
    position: absolute;
    right: 88px;
    top: 1px;
    width: 18px;
    height: 18px;
  }

/* This component has a fixed-size layout */
  .OpenlanguageSelector {
    min-height: 70px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 100px */
    height: 100%;
  }
  .OpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }


  .OpenlanguageSelector > .background > .elBg2 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 33px;
    width: 100.0%;
    height: 26px;
  }


  .OpenlanguageSelector > .background > .elBg1 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 26px;
  }


  .OpenlanguageSelector > .layoutFlow > .elChangeLangEn {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 5px;
    width: calc(100.0% - 5px);
  }
  .OpenlanguageSelector > .layoutFlow > .elChangeLangEn > * { 
    width: 100%;
  }
  


  .OpenlanguageSelector > .layoutFlow > .elChangeLangPtBR {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 5px;
    width: calc(100.0% - 5px);
  }
  .OpenlanguageSelector > .layoutFlow > .elChangeLangPtBR > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot1 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot1 > .layoutFlow > .elInteractivemap {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot1 > .layoutFlow > .elInteractivemap > * { 
    width: 100%;
  }
  


  .Spot1 > .layoutFlow > .elInteractivestreetext {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot1 > .layoutFlow > .elInteractivestreetext > * { 
    width: 100%;
  }
  


  .Spot1 > .layoutFlow > .elOnboardinginteractivemap {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot1 > .layoutFlow > .elOnboardinginteractivemap > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot2 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot2 > .layoutFlow > .elInteractivestreet {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot2 > .layoutFlow > .elInteractivestreet > * { 
    width: 100%;
  }
  


  .Spot2 > .layoutFlow > .elInteractivestreetext {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot2 > .layoutFlow > .elInteractivestreetext > * { 
    width: 100%;
  }
  


  .Spot2 > .layoutFlow > .elInteractivestreeimg {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot2 > .layoutFlow > .elInteractivestreeimg > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot3 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot3 > .layoutFlow > .elInteractivedashboard {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    width: 270px;
    margin-left: 0px;
  }
  .Spot3 > .layoutFlow > .elInteractivedashboard > * { 
  width: 270px;
  }
  


  .Spot3 > .layoutFlow > .elInteractivedashboardtext {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot3 > .layoutFlow > .elInteractivedashboardtext > * { 
    width: 100%;
  }
  


  .Spot3 > .layoutFlow > .elOnboardinginteractivemapCopy2 {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot3 > .layoutFlow > .elOnboardinginteractivemapCopy2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot4 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot4 > .layoutFlow > .elResults {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot4 > .layoutFlow > .elResults > * { 
    width: 100%;
  }
  


  .Spot4 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot4 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot5 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot5 > .layoutFlow > .elResults {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot5 > .layoutFlow > .elResults > * { 
    width: 100%;
  }
  


  .Spot5 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: -2px;
    margin-right: 0px;
    width: calc(100.0% - -2px);
  }
  .Spot5 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot6 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot6 > .layoutFlow > .elResults {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot6 > .layoutFlow > .elResults > * { 
    width: 100%;
  }
  


  .Spot6 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: -2px;
    margin-right: 0px;
    width: calc(100.0% - -2px);
  }
  .Spot6 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot9 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Spot9 > .layoutFlow > .elResults {
    line-height: 18px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Spot9 > .layoutFlow > .elResults > * { 
    width: 100%;
  }
  


  .Spot9 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: -2px;
    margin-right: 0px;
    width: calc(100.0% - -2px);
  }
  .Spot9 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

@keyframes fadeIn_HomeScreen_elNeighsomerville {
  0% { opacity: 0.0; }
  55% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.HomeScreen > .background > .elNeighsomerville {
  animation: 0.55s fadeIn_HomeScreen_elNeighsomerville;
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elNeighsomerville {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

@keyframes fadeIn_HomeScreen_elLogomark {
  0% { opacity: 0.0; }
  78% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.HomeScreen > .layoutFlow > .elLogomark {
  animation: 1.15s fadeIn_HomeScreen_elLogomark;
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elLogomark {
    position: relative;
    margin-top: 60px;
    width: 100px;
    margin-left: calc(49.7% - 50px);
  }
  .HomeScreen > .layoutFlow > .elLogomark > * { 
  width: 100px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elLogomark {
    position: relative;
    margin-top: 60px;
    width: 100px;
    margin-left: calc(49.8% - 50px);
  }
  .HomeScreen > .layoutFlow > .elLogomark > * { 
  width: 100px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elLogomark {
    position: relative;
    margin-top: 60px;
    width: 100px;
    margin-left: calc(49.9% - 50px);
  }
  .HomeScreen > .layoutFlow > .elLogomark > * { 
  width: 100px;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elLogomark {
    position: relative;
    margin-top: 60px;
    width: 100px;
    margin-left: calc(49.9% - 50px);
  }
  .HomeScreen > .layoutFlow > .elLogomark > * { 
  width: 100px;
  height: 100px;
  }
  
}

@keyframes fadeIn_HomeScreen_elLogofffffff {
  0% { opacity: 0.0; }
  80% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.HomeScreen > .layoutFlow > .elLogofffffff {
  animation: 1.25s fadeIn_HomeScreen_elLogofffffff;
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elLogofffffff {
    position: relative;
    margin-top: 30px;
    width: 203px;
    margin-left: calc(50.0% - 102px);
  }
  .HomeScreen > .layoutFlow > .elLogofffffff > * { 
  width: 203px;
  height: 28px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elLogofffffff {
    position: relative;
    margin-top: 30px;
    width: 203px;
    margin-left: calc(50.0% - 102px);
  }
  .HomeScreen > .layoutFlow > .elLogofffffff > * { 
  width: 203px;
  height: 28px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elLogofffffff {
    position: relative;
    margin-top: 30px;
    width: 202px;
    margin-left: calc(50.0% - 101px);
  }
  .HomeScreen > .layoutFlow > .elLogofffffff > * { 
  width: 202px;
  height: 27px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elLogofffffff {
    position: relative;
    margin-top: 30px;
    width: 202px;
    margin-left: calc(50.0% - 101px);
  }
  .HomeScreen > .layoutFlow > .elLogofffffff > * { 
  width: 202px;
  height: 27px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextCopy {
    line-height: 54px;
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextCopy {
    line-height: 54px;
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextCopy {
    line-height: 54px;
    position: relative;
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
    width: calc(100.0% - 100px);
  }
  .HomeScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextCopy {
    line-height: 54px;
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elTextCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    margin-left: 100px;
    margin-right: 100px;
    width: calc(100.0% - 200px);
  }
  .HomeScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}

  .HomeScreen > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HomeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .HomeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}

  .HomeScreen > .layoutFlow > .elColumns2 > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HomeScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .HomeScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}

  .HomeScreen > .layoutFlow > .elColumnsd > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
  }

  .HomeScreen > .layoutFlow > .elColumnsd > div > .col0 {
    flex: 100.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumnsd > div > .col0 {
    flex: 100.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumnsd > div > .col0 {
    flex: 100.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumnsd > div > .col0 {
    flex: 100.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumnsd > div > .col0 {
    flex: 100.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumnsd {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumnsd > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumnsd {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumnsd > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumnsd {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumnsd > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumnsd {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumnsd > * { 
    width: 100%;
  }
  
}

  .HomeScreen > .layoutFlow > .elColumn3 > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HomeScreen > .layoutFlow > .elColumn3 > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .HomeScreen > .layoutFlow > .elColumn3 > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 40.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HomeScreen > .layoutFlow > .elColumn3 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elColumn3 {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumn3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elColumn3 {
    position: relative;
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .HomeScreen > .layoutFlow > .elColumn3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elColumn3 {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumn3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elColumn3 {
    position: relative;
    margin-top: 40px;
    margin-left: 200px;
    margin-right: 200px;
    width: calc(100.0% - 400px);
  }
  .HomeScreen > .layoutFlow > .elColumn3 > * { 
    width: 100%;
  }
  
}

.HomeScreen .elFooter {
}

.HomeScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes _default_HomeScreen_elFooter {
  0% { opacity: 0.0; }
  83% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.HomeScreen > .layoutFlow > .elFooter {
  animation: 1.45s _default_HomeScreen_elFooter;
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elFooter > * { 
    width: 100%;
  }
  
  .HomeScreen > .layoutFlow > .elFooter > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elRect {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elRect {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elRect {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elRect {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}

.HomeScreen .elOpenlanguageSelector {
}

.HomeScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 37px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 37px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 37px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 37px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}

.HomeScreen .elSelector {
}

.HomeScreen .elSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elSelector {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 110px;
    height: 19px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elSelector > .background > .containerMinHeight {
    min-height: 19px;
  }

@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elSelector {
    right: 10px;
    top: 10px;
    width: 110px;
    height: 19px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elSelector > .background > .containerMinHeight {
    min-height: 19px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elSelector {
    right: 0px;
    top: 10px;
    width: 109px;
    height: 19px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elSelector > .background > .containerMinHeight {
    min-height: 19px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elSelector {
    right: 10px;
    top: 10px;
    width: 109px;
    height: 19px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elSelector > .background > .containerMinHeight {
    min-height: 19px;
  }

}


/* Narrowest size for this element's responsive layout */
  .N404Screen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 189px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .N404Screen > .layoutFlow > .elText > * { 
  width: 280px;
  }
  
@media (min-width: 568px) {
  .N404Screen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 189px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .N404Screen > .layoutFlow > .elText > * { 
  width: 280px;
  }
  
}
@media (min-width: 768px) {
  .N404Screen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 188px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .N404Screen > .layoutFlow > .elText > * { 
  width: 278px;
  }
  
}
@media (min-width: 1024px) {
  .N404Screen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 188px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .N404Screen > .layoutFlow > .elText > * { 
  width: 278px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .SubscribeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .SubscribeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .SubscribeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .SubscribeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

  .SubscribeScreen > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .SubscribeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 383px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .SubscribeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 19.9px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 383px;
    margin-left: 19.9px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}

  .SubscribeScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 19.9px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 100.0;  /* Column width for this screen format */
    flex-basis: 383px;
    margin-left: 0.0px;
    margin-top: 19.9px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .SubscribeScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .SubscribeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    width: 272px;
    margin-left: calc(50.0% - 136px);
  }
  .SubscribeScreen > .layoutFlow > .elColumns > * { 
  width: 272px;
  }
  
@media (min-width: 568px) {
  .SubscribeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    width: 272px;
    margin-left: calc(50.0% - 136px);
  }
  .SubscribeScreen > .layoutFlow > .elColumns > * { 
  width: 272px;
  }
  
}
@media (min-width: 768px) {
  .SubscribeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    width: 740px;
    margin-left: calc(50.0% - 370px);
  }
  .SubscribeScreen > .layoutFlow > .elColumns > * { 
  width: 740px;
  }
  
}
@media (min-width: 1024px) {
  .SubscribeScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    width: 959px;
    margin-left: calc(50.0% - 480px);
  }
  .SubscribeScreen > .layoutFlow > .elColumns > * { 
  width: 959px;
  }
  
}

.SubscribeScreen .elOpenlanguageSelector {
}

.SubscribeScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 30px;
    top: 65px;
    width: 100px;
    height: 70px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 100px;
    height: 70px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 99px;
    height: 70px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 99px;
    height: 70px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .SubscribeScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state0_elBgphoto389432 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state0_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state0_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state0_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state0_elBackground735289 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state0_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state0_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state0_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 > * { 
  width: 315px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 > * { 
  width: 315px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 > * { 
  width: 313px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText915386 > * { 
  width: 313px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText3757268 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 > * { 
  width: 280px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 > * { 
  width: 280px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 > * { 
  width: 278px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elText275683 > * { 
  width: 278px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elNamefield988237 > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgegroup540737 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 > * { 
  width: 101px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 > * { 
  width: 101px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elAgepicker820017 > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng {
    position: relative;
    margin-top: 5px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng > * { 
  width: 160px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng {
    position: relative;
    margin-top: 5px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng > * { 
  width: 160px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng {
    position: relative;
    margin-top: 5px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng > * { 
  width: 159px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng {
    position: relative;
    margin-top: 5px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elGenderPickerEng > * { 
  width: 159px;
  }
  
}

.AccountSetupScreen .state0_elCompmyGeo527660 {
}

.AccountSetupScreen .state0_elCompmyGeo527660.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state0_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state0_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state0_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state0_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state0_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elValidator264817 > * { 
  width: 236px;
  }
  
}

.AccountSetupScreen .state0_elButton761106 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AccountSetupScreen .state0_elButton761106 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state0_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state0_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .screenFgContainer > .foreground > .state0_elClose538058 {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state0_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state0_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state0_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state1_elBgphoto389432 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state1_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state1_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state1_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state1_elBackground735289 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state1_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state1_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state1_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 > * { 
  width: 315px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 > * { 
  width: 315px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 > * { 
  width: 313px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText915386 > * { 
  width: 313px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText3757268 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 > * { 
  width: 280px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 > * { 
  width: 280px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 > * { 
  width: 278px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elText275683 > * { 
  width: 278px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elNamefield988237 > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgegroup540737 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 > * { 
  width: 101px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 > * { 
  width: 101px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elAgepicker820017 > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr {
    position: relative;
    margin-top: 5px;
    width: 130px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr {
    position: relative;
    margin-top: 5px;
    width: 130px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr {
    position: relative;
    margin-top: 5px;
    width: 129px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr > * { 
  width: 129px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr {
    position: relative;
    margin-top: 5px;
    width: 129px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elGenderPickerbr > * { 
  width: 129px;
  }
  
}

.AccountSetupScreen .state1_elCompmyGeo527660 {
}

.AccountSetupScreen .state1_elCompmyGeo527660.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state1_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state1_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state1_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state1_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state1_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elValidator264817 > * { 
  width: 236px;
  }
  
}

.AccountSetupScreen .state1_elButton761106 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AccountSetupScreen .state1_elButton761106 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state1_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state1_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .screenFgContainer > .foreground > .state1_elClose538058 {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state1_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state1_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state1_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state2_elBgphoto389432 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state2_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state2_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state2_elBgphoto389432 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .background > .state2_elBackground735289 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .background > .state2_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .background > .state2_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .background > .state2_elBackground735289 {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 > * { 
  width: 315px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 > * { 
  width: 315px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 > * { 
  width: 313px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText915386 > * { 
  width: 313px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 > * { 
  width: 280px;
  height: 2px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elProgressBar1858633 > * { 
  width: 278px;
  height: 2px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText3757268 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 > * { 
  width: 280px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 > * { 
  width: 280px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 > * { 
  width: 278px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elText275683 > * { 
  width: 278px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 {
    position: relative;
    margin-top: 40px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elNamefield988237 > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgegroup540737 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 > * { 
  width: 101px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 > * { 
  width: 101px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 > * { 
  width: 100px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elAgepicker820017 > * { 
  width: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 > * { 
  width: 120px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 > * { 
  width: 120px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 119px;
    margin-left: calc(50.0% - 60px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elIssueCopy948448 > * { 
  width: 119px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp {
    position: relative;
    margin-top: 5px;
    width: 130px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp > * { 
  width: 130px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp {
    position: relative;
    margin-top: 5px;
    width: 130px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp > * { 
  width: 130px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp {
    position: relative;
    margin-top: 5px;
    width: 129px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp > * { 
  width: 129px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp {
    position: relative;
    margin-top: 5px;
    width: 129px;
    margin-left: calc(50.0% - 65px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elGenderPickeresp > * { 
  width: 129px;
  }
  
}

.AccountSetupScreen .state2_elCompmyGeo527660 {
}

.AccountSetupScreen .state2_elCompmyGeo527660.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state2_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > * { 
  width: 290px;
  }
  
  .flowRow_AccountSetupScreen_state2_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state2_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 {
    position: absolute;
    margin-top: 20px;
    width: 288px;
    margin-left: calc(50.0% - 144px);
  }
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > * { 
  width: 288px;
  }
  
  .flowRow_AccountSetupScreen_state2_elCompmyGeo527660_527660 { 
  }
  
  .AccountSetupScreen > .layoutFlow > .flowRow > .state2_elCompmyGeo527660 > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elValidator264817 > * { 
  width: 236px;
  }
  
}

.AccountSetupScreen .state2_elButton761106 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AccountSetupScreen .state2_elButton761106 button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 {
    line-height: 35px;
    position: relative;
    margin-top: 25px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AccountSetupScreen > .layoutFlow > .state2_elButton761106 > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacer774283 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .AccountSetupScreen > .layoutFlow > .state2_elSpacerCopy455585 > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AccountSetupScreen > .screenFgContainer > .foreground > .state2_elClose538058 {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state2_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state2_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .AccountSetupScreen > .screenFgContainer > .foreground > .state2_elClose538058 {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .FeedScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .FeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .FeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .FeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

  .FeedScreen > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .FeedScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1124px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1023px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col0 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .FeedScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1124px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1023px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col1 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}

  .FeedScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1124px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 1023px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .FeedScreen > .layoutFlow > .elColumns > div > .col2 {
    flex: 33.3;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .FeedScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FeedScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    margin-left: 40px;
    margin-right: 40px;
    width: calc(100.0% - 80px);
  }
  .FeedScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FeedScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    margin-left: 260px;
    margin-right: 160px;
    width: calc(100.0% - 420px);
  }
  .FeedScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 80px;
    margin-left: 280px;
    margin-right: 300px;
    width: calc(100.0% - 580px);
  }
  .FeedScreen > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  
}

  .FeedScreen > .layoutFlow > .elColumns2 > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .FeedScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 1124px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 1023px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .FeedScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 1124px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 1023px;
    margin-left: 0.0px;
    margin-top: 39.8px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .FeedScreen > .layoutFlow > .elColumns2 > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 40.0px;
    width: 0px;
    min-height: auto;
  }

}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .FeedScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FeedScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    width: calc(100.0% - 80px);
  }
  .FeedScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FeedScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 250px;
    margin-right: 250px;
    width: calc(100.0% - 500px);
  }
  .FeedScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elColumns2 {
    position: relative;
    margin-top: 40px;
    margin-left: 350px;
    margin-right: 350px;
    width: calc(100.0% - 700px);
  }
  .FeedScreen > .layoutFlow > .elColumns2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FeedScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .FeedScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .FeedScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .FeedScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .FeedScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .FeedScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .FeedScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .FeedScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}

.FeedScreen .elOpenlanguageSelector {
}

.FeedScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    position: absolute;
    left: 20px;
    top: 80px;
    width: 242px;
    height: 433px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 242px;
    height: 433px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 200px;
    height: 500px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 430px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}


/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elRect {
    position: absolute;
    left: -50px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elRect {
    left: 29px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elRect {
    left: 216px;
    top: 105px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 105px;
    width: 10px;
    height: 10px;
  }
}

.FeedScreen .elCompbg {
}

.FeedScreen .elCompbg.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: calc(100% + 0.0% + -1.0px);
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}

.FeedScreen .elMenucreate {
}

.FeedScreen .elMenucreate.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate {
    position: fixed;
    left: calc(50.0% + 0px - 61px);
    bottom: 85px;
    width: 122px;
    height: 215px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate > .background > .containerMinHeight {
    min-height: 215px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 85px;
    width: 122px;
    height: 215px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate > .background > .containerMinHeight {
    min-height: 215px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 84px;
    width: 121px;
    height: 214px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate > .background > .containerMinHeight {
    min-height: 214px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 84px;
    width: 121px;
    height: 214px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elMenucreate > .background > .containerMinHeight {
    min-height: 214px;
  }

}

.FeedScreen .elCompnafooter {
  pointer-events: auto;
}

.FeedScreen .elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .FeedScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}

/* This component has a fixed-size layout */
  .CompmyGeo {
    width: 100%; /* This component was designed using a width of 270px */
    height: 100%;
  }


  .CompmyGeo > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: -2px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .CompmyGeo > .layoutFlow > .elEmbed > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Geoloc {
    min-height: 70px;  /* Minimum height to ensure layout flow is visible */
    min-width: 270px; /* This component has a fixed size */
  }
  .Geoloc > .background > .containerMinHeight {
    min-height: 70px;
  }


  .Geoloc > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 2px;
    width: 150px;
    margin-left: 25px;
  }
  .Geoloc > .layoutFlow > .elText > * { 
  width: 150px;
  }
  


  .Geoloc > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Geoloc > .layoutFlow > .elEmbed > * { 
    width: 100%;
  }
  


  .Geoloc > .foreground > .elIcon {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 28px;
  }

.Geoloc .elMapicon {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Geoloc > .foreground > .elMapicon {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 221px;
    height: 30px;
  }


/* Narrowest size for this element's responsive layout */
  .WaitScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WaitScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WaitScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WaitScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

@keyframes _default_WaitScreen_state0_elText {
  0% { opacity: 0.0; }
  76% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.WaitScreen > .layoutFlow > .state0_elText {
  animation: 1.05s _default_WaitScreen_state0_elText;
}

/* Narrowest size for this element's responsive layout */
  .WaitScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 200px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .WaitScreen > .layoutFlow > .state0_elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .WaitScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 200px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .WaitScreen > .layoutFlow > .state0_elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .WaitScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 199px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .WaitScreen > .layoutFlow > .state0_elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .WaitScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 199px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .WaitScreen > .layoutFlow > .state0_elText > * { 
  width: 237px;
  }
  
}

@keyframes fadeIn_WaitScreen_state0_elPreloader {
  0% { opacity: 0.0; }
  78% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.WaitScreen > .layoutFlow > .state0_elPreloader {
  animation: 1.15s fadeIn_WaitScreen_state0_elPreloader;
}

/* Narrowest size for this element's responsive layout */
  .WaitScreen > .layoutFlow > .state0_elPreloader {
    position: relative;
    margin-top: 0px;
    width: 124px;
    margin-left: calc(50.0% - 62px);
  }
  .WaitScreen > .layoutFlow > .state0_elPreloader > * { 
  width: 124px;
  height: 65px;
  }
  
@media (min-width: 568px) {
  .WaitScreen > .layoutFlow > .state0_elPreloader {
    position: relative;
    margin-top: 0px;
    width: 124px;
    margin-left: calc(50.0% - 62px);
  }
  .WaitScreen > .layoutFlow > .state0_elPreloader > * { 
  width: 124px;
  height: 65px;
  }
  
}
@media (min-width: 768px) {
  .WaitScreen > .layoutFlow > .state0_elPreloader {
    position: relative;
    margin-top: 0px;
    width: 123px;
    margin-left: calc(50.0% - 62px);
  }
  .WaitScreen > .layoutFlow > .state0_elPreloader > * { 
  width: 123px;
  height: 64px;
  }
  
}
@media (min-width: 1024px) {
  .WaitScreen > .layoutFlow > .state0_elPreloader {
    position: relative;
    margin-top: 0px;
    width: 123px;
    margin-left: calc(50.0% - 62px);
  }
  .WaitScreen > .layoutFlow > .state0_elPreloader > * { 
  width: 123px;
  height: 64px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .WaitScreen > .background > .state1_elNavigatoronLine {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WaitScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WaitScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WaitScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.WaitScreen .state1_elGlobalcheckConnection {
}

.WaitScreen .state1_elGlobalcheckConnection.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

@media (min-width: 568px) {
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 768px) {
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 1024px) {
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .WaitScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}


/* Narrowest size for this element's responsive layout */
  .WaitScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .WaitScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
@media (min-width: 568px) {
  .WaitScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .WaitScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
}
@media (min-width: 768px) {
  .WaitScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .WaitScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}
@media (min-width: 1024px) {
  .WaitScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .WaitScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}

.WaitScreen .state1_elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .WaitScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WaitScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WaitScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WaitScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WelcomeScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WelcomeScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WelcomeScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .screenFgContainer > .foreground > .state0_elPreloader {
    position: absolute;
    left: calc(50.0% + 0px - 62px);
    top: 230px;
    width: 124px;
    height: 65px;
  }
@media (min-width: 568px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state0_elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 230px;
    width: 124px;
    height: 65px;
  }
}
@media (min-width: 768px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state0_elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 229px;
    width: 123px;
    height: 64px;
  }
}
@media (min-width: 1024px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state0_elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 229px;
    width: 123px;
    height: 64px;
  }
}


/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .background > .state1_elNavigatoronLine {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WelcomeScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WelcomeScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WelcomeScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.WelcomeScreen .state1_elGlobalcheckConnection {
}

.WelcomeScreen .state1_elGlobalcheckConnection.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

@media (min-width: 568px) {
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 768px) {
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 1024px) {
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .WelcomeScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}


/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
@media (min-width: 568px) {
  .WelcomeScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
}
@media (min-width: 768px) {
  .WelcomeScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}
@media (min-width: 1024px) {
  .WelcomeScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .WelcomeScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}

.WelcomeScreen .state1_elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .WelcomeScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .WelcomeScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .background > .elBgphoto {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .GeoaddressScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .GeoaddressScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
@media (min-width: 568px) {
  .GeoaddressScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
}
@media (min-width: 768px) {
  .GeoaddressScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elTitle1 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle1 > * { 
  width: 315px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elTitle1 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 315px;
    margin-left: calc(50.0% - 158px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle1 > * { 
  width: 315px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elTitle1 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle1 > * { 
  width: 313px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elTitle1 {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 313px;
    margin-left: calc(50.0% - 156px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle1 > * { 
  width: 313px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 > * { 
  width: 280px;
  height: 2px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 > * { 
  width: 280px;
  height: 2px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 > * { 
  width: 278px;
  height: 2px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .GeoaddressScreen > .layoutFlow > .elProgressBar2 > * { 
  width: 278px;
  height: 2px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elTitle2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle2 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elTitle2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle2 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elTitle2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle2 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elTitle2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle2 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elTitle3 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elTitle3 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elTitle3 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle3 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elTitle3 {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .GeoaddressScreen > .layoutFlow > .elTitle3 > * { 
  width: 320px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elFieldnumber {
    position: relative;
    margin-top: 10px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .GeoaddressScreen > .layoutFlow > .elFieldnumber > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elFieldnumber {
    position: relative;
    margin-top: 10px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .GeoaddressScreen > .layoutFlow > .elFieldnumber > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elFieldnumber {
    position: relative;
    margin-top: 10px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .GeoaddressScreen > .layoutFlow > .elFieldnumber > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elFieldnumber {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .GeoaddressScreen > .layoutFlow > .elFieldnumber > * { 
  width: 320px;
  }
  
}

.GeoaddressScreen .elCompGeo {
}

.GeoaddressScreen .elCompGeo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elCompGeo {
    position: relative;
    margin-top: 30px;
    width: 272px;
    margin-left: calc(50.0% - 136px);
  }
  .GeoaddressScreen > .layoutFlow > .elCompGeo > * { 
  width: 272px;
  }
  
  .GeoaddressScreen > .layoutFlow > .elCompGeo > .background > .containerMinHeight {
    min-height: 170px;
  }

@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elCompGeo {
    position: relative;
    margin-top: 30px;
    width: 272px;
    margin-left: calc(50.0% - 136px);
  }
  .GeoaddressScreen > .layoutFlow > .elCompGeo > * { 
  width: 272px;
  }
  
  .GeoaddressScreen > .layoutFlow > .elCompGeo > .background > .containerMinHeight {
    min-height: 170px;
  }

}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elCompGeo {
    position: relative;
    margin-top: 30px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .GeoaddressScreen > .layoutFlow > .elCompGeo > * { 
  width: 270px;
  }
  
  .GeoaddressScreen > .layoutFlow > .elCompGeo > .background > .containerMinHeight {
    min-height: 170px;
  }

}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elCompGeo {
    position: relative;
    margin-top: 30px;
    width: 360px;
    margin-left: calc(50.0% - 180px);
  }
  .GeoaddressScreen > .layoutFlow > .elCompGeo > * { 
  width: 360px;
  }
  
  .GeoaddressScreen > .layoutFlow > .elCompGeo > .background > .containerMinHeight {
    min-height: 170px;
  }

}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .GeoaddressScreen > .layoutFlow > .elValidator > * { 
  width: 270px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .GeoaddressScreen > .layoutFlow > .elValidator > * { 
  width: 270px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .GeoaddressScreen > .layoutFlow > .elValidator > * { 
  width: 268px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .GeoaddressScreen > .layoutFlow > .elValidator > * { 
  width: 280px;
  }
  
}

.GeoaddressScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.GeoaddressScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .GeoaddressScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .GeoaddressScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .GeoaddressScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .GeoaddressScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .GeoaddressScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .GeoaddressScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .GeoaddressScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .GeoaddressScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .GeoaddressScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .GeoaddressScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 40px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .GeoaddressScreen > .screenFgContainer > .foreground > .elClose {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .GeoaddressScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .GeoaddressScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .GeoaddressScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 200px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .EditonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .EditonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 200px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .EditonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .EditonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 199px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .EditonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}
@media (min-width: 1024px) {
  .EditonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 199px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .EditonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elGoogleMaps {
    position: absolute;
    left: 20px;
    top: -11px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGoogleMaps {
    left: 20px;
    top: -11px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGoogleMaps {
    left: 20px;
    top: -11px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGoogleMaps {
    left: 20px;
    top: -11px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elGeofirecommon {
    position: absolute;
    left: 10px;
    top: -12px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGeofirecommon {
    left: 10px;
    top: -12px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGeofirecommon {
    left: 10px;
    top: -12px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGeofirecommon {
    left: 10px;
    top: -12px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elMarkerclusterer {
    position: absolute;
    left: -10px;
    top: 55px;
    width: 23px;
    height: 23px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMarkerclusterer {
    left: -10px;
    top: 55px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMarkerclusterer {
    left: -10px;
    top: 55px;
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMarkerclusterer {
    left: -10px;
    top: 55px;
    width: 23px;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: calc(100% + 0.0% + 200.0px);
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: calc(100% + 0.0% + 200.0px);
  }
}

.EditonmapScreen .elButtonset {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.EditonmapScreen .elButtonset:focus {
  outline: none;
}


@keyframes fadeIn_EditonmapScreen_elButtonset {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elButtonset {
  animation: 3.25s fadeIn_EditonmapScreen_elButtonset;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    position: fixed;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 159px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 159px;
    height: 35px;
  }
}

.EditonmapScreen .elBgcomp2 {
}

.EditonmapScreen .elBgcomp2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_EditonmapScreen_elBgcomp2 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
  animation: 4.00s fadeIn_EditonmapScreen_elBgcomp2;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    position: absolute;
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 135px;
    width: 298px;
    height: 50px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 135px;
    width: 298px;
    height: 50px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}

.EditonmapScreen .elBgcomp1 {
}

.EditonmapScreen .elBgcomp1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_EditonmapScreen_elBgcomp1 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
  animation: 4.00s fadeIn_EditonmapScreen_elBgcomp1;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    position: absolute;
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 298px;
    height: 99px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 298px;
    height: 99px;
  }
  .EditonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}

@keyframes fadeIn_EditonmapScreen_elLtlng {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elLtlng {
  animation: 4.00s fadeIn_EditonmapScreen_elLtlng;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 278px;
    height: 83px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 278px;
    height: 83px;
  }
}

@keyframes fadeIn_EditonmapScreen_elGuide {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elGuide {
  animation: 4.00s fadeIn_EditonmapScreen_elGuide;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 147px;
    width: 278px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 147px;
    width: 278px;
    height: 35px;
  }
}

@keyframes fadeIn_EditonmapScreen_elClose {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.EditonmapScreen > .screenFgContainer > .foreground > .elClose {
  animation: 3.00s fadeIn_EditonmapScreen_elClose;
}

/* Narrowest size for this element's responsive layout */
  .EditonmapScreen > .screenFgContainer > .foreground > .elClose {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .EditonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}

/* This component has a fixed-size layout */
  .Compbg {
    min-height: 40px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 280px */
    height: 100%;
  }
  .Compbg > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Compbg > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Compbg > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Compbg > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

@keyframes _default_PostLoginLandingPageScreen_state0_elText {
  0% { opacity: 0.0; }
  76% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.PostLoginLandingPageScreen > .layoutFlow > .state0_elText {
  animation: 1.05s _default_PostLoginLandingPageScreen_state0_elText;
}

/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 200px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 200px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 199px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 199px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state0_elText > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .background > .state1_elNavigatoronLine {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .background > .state1_elNavigatoronLine {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.PostLoginLandingPageScreen .state1_elGlobalcheckConnection {
}

.PostLoginLandingPageScreen .state1_elGlobalcheckConnection.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 182px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 320px;
  }
  
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection {
    position: relative;
    margin-top: 181px;
    width: 318px;
    margin-left: calc(50.0% - 159px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > * { 
  width: 318px;
  }
  
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elGlobalcheckConnection > .background > .containerMinHeight {
    min-height: 50px;
  }

}


/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 160px;
    margin-left: calc(50.0% - 80px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry > * { 
  width: 160px;
  }
  
}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 159px;
    margin-left: calc(50.0% - 80px);
  }
  .PostLoginLandingPageScreen > .layoutFlow > .state1_elRetry > * { 
  width: 159px;
  }
  
}

.PostLoginLandingPageScreen .state1_elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .PostLoginLandingPageScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PostLoginLandingPageScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PostLoginLandingPageScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PostLoginLandingPageScreen > .screenFgContainer > .foreground > .state1_elHotspot {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

/* This component has a fixed-size layout */
  .GlobalcheckConnection {
    min-height: 53px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .GlobalcheckConnection > .background > .containerMinHeight {
    min-height: 53px;
  }


  .GlobalcheckConnection > .layoutFlow > .elNoun_cloudConnectionLost_9278842 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .GlobalcheckConnection > .layoutFlow > .elNoun_cloudConnectionLost_9278842 > * { 
  width: 320px;
  height: 27px;
  }
  


  .GlobalcheckConnection > .layoutFlow > .elTextlostconnection {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: 11px;
  }
  .GlobalcheckConnection > .layoutFlow > .elTextlostconnection > * { 
  width: 300px;
  }
  

/* This component has a fixed-size layout */
  .Privacynotice {
    min-width: 302px; /* This component has a fixed size */
  }


  .Privacynotice > .layoutFlow > .elTextCopy2 {
    line-height: 16px;
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: 0px;
  }
  .Privacynotice > .layoutFlow > .elTextCopy2 > * { 
  width: 300px;
  }
  

.Privacynotice .elPrivacy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Privacynotice > .foreground > .elPrivacy {
    position: absolute;
    left: 177px;
    top: 16px;
    width: 124px;
    height: 13px;
  }

.Privacynotice .elTerms {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Privacynotice > .foreground > .elTerms {
    position: absolute;
    left: 66px;
    top: 16px;
    width: 89px;
    height: 13px;
  }

/* This component has a fixed-size layout */
  .Cookieconsent {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    min-width: 320px; /* This component has a fixed size */
  }
  .Cookieconsent > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Cookieconsent > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Cookieconsent > .layoutFlow > .elText {
    line-height: 14px;
    position: relative;
    margin-top: 9px;
    margin-left: 44px;
    margin-right: 77px;
    width: calc(100.0% - 121px);
  }
  .Cookieconsent > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .Cookieconsent > .foreground > .elCookies {
    position: absolute;
    left: 8px;
    top: 17px;
    width: 28px;
    height: 28px;
  }


  .Cookieconsent > .foreground > .elButtonbg {
    position: absolute;
    right: 10px;
    top: 17px;
    width: 64px;
    height: 25px;
  }

.Cookieconsent .elButton {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Cookieconsent .elButton:focus {
  outline: none;
}



  .Cookieconsent > .foreground > .elButton {
    position: absolute;
    right: 10px;
    top: 17px;
    width: 64px;
    height: 25px;
  }

.Cookieconsent .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Cookieconsent > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    margin-left: 230px;
    margin-right: 280px;
    width: calc(100.0% - 510px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 230px;
    margin-right: 280px;
    width: calc(100.0% - 510px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 23px;
    margin-right: 20px;
    width: calc(100.0% - 43px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 23px;
    margin-right: 20px;
    width: calc(100.0% - 43px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 230px;
    margin-right: 200px;
    width: calc(100.0% - 430px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .PrivacyPolicyScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .PrivacyPolicyScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    position: absolute;
    top: 15px;
    width: 230px;
    height: 26px;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 230px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 229px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 229px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elBack {
    position: absolute;
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}

.PrivacyPolicyScreen .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 63px;
    height: 43px;
  }
@media (min-width: 568px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 63px;
    height: 43px;
  }
}
@media (min-width: 768px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 62px;
    height: 42px;
  }
}
@media (min-width: 1024px) {
  .PrivacyPolicyScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 62px;
    height: 42px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .TermsandConditionsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TermsandConditionsScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 50px;
    margin-left: 230px;
    margin-right: 280px;
    width: calc(100.0% - 510px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 40px;
    width: calc(100.0% - 60px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 230px;
    margin-right: 280px;
    width: calc(100.0% - 510px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elTextCopy19 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText3 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TermsandConditionsScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 230px;
    margin-right: 200px;
    width: calc(100.0% - 430px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText3 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 230px;
    margin-right: 250px;
    width: calc(100.0% - 480px);
  }
  .TermsandConditionsScreen > .layoutFlow > .elText3 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
@media (min-width: 568px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .TermsandConditionsScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 60px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
@media (min-width: 568px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 40px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    position: absolute;
    top: 15px;
    width: 240px;
    height: 26px;
  }
@media (min-width: 568px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 240px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 239px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elScrTitle {
    overflow: hidden;
    top: 15px;
    width: 239px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elBack {
    position: absolute;
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
@media (min-width: 568px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elBack {
    left: 10px;
    top: 11px;
    width: 24px;
    height: 20px;
  }
}

.TermsandConditionsScreen .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 63px;
    height: 43px;
  }
@media (min-width: 568px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 63px;
    height: 43px;
  }
}
@media (min-width: 768px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 62px;
    height: 42px;
  }
}
@media (min-width: 1024px) {
  .TermsandConditionsScreen > .screenFgContainer > .foreground > .elHotspot {
    left: 0px;
    top: 0px;
    width: 62px;
    height: 42px;
  }
}

/* This component has a fixed-size layout */
  .OpenlanguageSelectormobile {
    min-height: 70px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 100px */
    height: 100%;
  }
  .OpenlanguageSelectormobile > .background > .containerMinHeight {
    min-height: 70px;
  }


  .OpenlanguageSelectormobile > .background > .elBg2 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 33px;
    width: 100.0%;
    height: 26px;
  }


  .OpenlanguageSelectormobile > .background > .elBg1 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 26px;
  }


  .OpenlanguageSelectormobile > .layoutFlow > .elChangeLangEn {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 5px;
    width: calc(100.0% - 5px);
  }
  .OpenlanguageSelectormobile > .layoutFlow > .elChangeLangEn > * { 
    width: 100%;
  }
  


  .OpenlanguageSelectormobile > .layoutFlow > .elChangeLangPtBR {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 5px;
    width: calc(100.0% - 5px);
  }
  .OpenlanguageSelectormobile > .layoutFlow > .elChangeLangPtBR > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .PlanComp {
    min-height: 822px;  /* Minimum height to ensure layout flow is visible */
    min-width: 320px; /* This component has a fixed size */
  }
  .PlanComp > .background > .containerMinHeight {
    min-height: 822px;
  }

.PlanComp .state0_elPrice1 {
}

.PlanComp .state0_elPrice1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .PlanComp > .layoutFlow > .state0_elPrice1 {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .PlanComp > .layoutFlow > .state0_elPrice1 > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .PlanComp {
    min-height: 822px;  /* Minimum height to ensure layout flow is visible */
    min-width: 320px; /* This component has a fixed size */
  }
  .PlanComp > .background > .containerMinHeight {
    min-height: 822px;
  }

.PlanComp .state1_elPrice1 {
}

.PlanComp .state1_elPrice1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .PlanComp > .layoutFlow > .state1_elPrice1 {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .PlanComp > .layoutFlow > .state1_elPrice1 > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .PlanComp {
    min-height: 822px;  /* Minimum height to ensure layout flow is visible */
    min-width: 320px; /* This component has a fixed size */
  }
  .PlanComp > .background > .containerMinHeight {
    min-height: 822px;
  }

.PlanComp .state2_elPrice1 {
}

.PlanComp .state2_elPrice1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .PlanComp > .layoutFlow > .state2_elPrice1 {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% - 160px);
  }
  .PlanComp > .layoutFlow > .state2_elPrice1 > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .Logout {
    min-height: 150px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Logout > .background > .containerMinHeight {
    min-height: 150px;
  }


  .Logout > .layoutFlow > .state0_elText {
    overflow: hidden;
    position: relative;
    margin-top: 3px;
    width: 190px;
    margin-left: 34px;
  }
  .Logout > .layoutFlow > .state0_elText > * { 
  width: 190px;
  }
  


  .Logout > .foreground > .state0_elUsernophotosetIcon {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 26px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .Logout {
    min-height: 150px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Logout > .background > .containerMinHeight {
    min-height: 150px;
  }


  .Logout > .layoutFlow > .state1_elText {
    overflow: hidden;
    position: relative;
    margin-top: 3px;
    width: 190px;
    margin-left: 34px;
  }
  .Logout > .layoutFlow > .state1_elText > * { 
  width: 190px;
  }
  


  .Logout > .foreground > .state1_elUsernophotosetIcon {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 26px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .Drawer {
    min-height: 213px;  /* Minimum height to ensure layout flow is visible */
    width: 280px; /* This component has a fixed size */
  }
  .Drawer > .background > .containerMinHeight {
    min-height: 213px;
  }


  .Drawer > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Drawer .elSelectordrawer {
}

.Drawer .elSelectordrawer.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Drawer > .layoutFlow > .elSelectordrawer {
    position: relative;
    margin-top: 20px;
    width: 230px;
    margin-left: 20px;
  }
  .Drawer > .layoutFlow > .elSelectordrawer > * { 
  width: 230px;
  }
  

.Drawer .elOpenlanguageSelectormobile {
}

.Drawer .elOpenlanguageSelectormobile.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Drawer > .layoutFlow > .elOpenlanguageSelectormobile {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: 20px;
  }
  .Drawer > .layoutFlow > .elOpenlanguageSelectormobile > * { 
  width: 100px;
  }
  


  .Drawer > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: 20px;
  }
  .Drawer > .layoutFlow > .elText > * { 
  width: 100px;
  }
  

.Drawer .elLogout {
}

.Drawer .elLogout.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Drawer > .layoutFlow > .elLogout {
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: 20px;
  }
  .Drawer > .layoutFlow > .elLogout > * { 
  width: 250px;
  }
  

/* This component has a fixed-size layout */
  .Menu {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .Menu > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menu > .background > .state0_elBackground893156 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menu > .layoutFlow > .flowRow > .state0_elContato556013 {
    overflow: hidden;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Menu > .layoutFlow > .flowRow > .state0_elContato556013 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 218px;
  }
  
  .flowRow_Menu_state0_elContato556013_556013 { 
  }
  

.Menu .state0_elLogout557043 {
}

.Menu .state0_elLogout557043.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menu > .layoutFlow > .flowRow > .state0_elLogout557043 {
    position: absolute;
    top: 16px;
    width: 100%;
  }
  .Menu > .layoutFlow > .flowRow > .state0_elLogout557043 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: -4px;
  }
  

.Menu .state0_elSelector286199 {
}

.Menu .state0_elSelector286199.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menu > .layoutFlow > .state0_elSelector286199 {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Menu > .layoutFlow > .state0_elSelector286199 > * { 
  margin-left: auto;
  width: 110px;
  margin-right: 99px;
  }
  


  .Menu > .foreground > .state0_elRect990514 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }


  .Menu > .foreground > .state0_elCoUrbaniza461992 {
    position: absolute;
    left: 30px;
    top: 21px;
    width: 155px;
    height: 21px;
  }

/* This component has a fixed-size layout */
  .Menu {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .Menu > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menu > .background > .state1_elBackground893156 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menu > .layoutFlow > .flowRow > .state1_elContato556013 {
    overflow: hidden;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Menu > .layoutFlow > .flowRow > .state1_elContato556013 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 218px;
  }
  
  .flowRow_Menu_state1_elContato556013_556013 { 
  }
  

.Menu .state1_elLogout557043 {
}

.Menu .state1_elLogout557043.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menu > .layoutFlow > .flowRow > .state1_elLogout557043 {
    position: absolute;
    top: 16px;
    width: 100%;
  }
  .Menu > .layoutFlow > .flowRow > .state1_elLogout557043 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: -4px;
  }
  

.Menu .state1_elSelector286199 {
}

.Menu .state1_elSelector286199.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menu > .layoutFlow > .state1_elSelector286199 {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Menu > .layoutFlow > .state1_elSelector286199 > * { 
  margin-left: auto;
  width: 110px;
  margin-right: 99px;
  }
  


  .Menu > .foreground > .state1_elRect990514 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }


  .Menu > .foreground > .state1_elCoUrbaniza461992 {
    position: absolute;
    left: 30px;
    top: 21px;
    width: 155px;
    height: 21px;
  }

/* This component has a fixed-size layout */
  .Menumobile {
    min-height: 60px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Menumobile > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menumobile > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menumobile > .foreground > .elRect {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }


  .Menumobile > .foreground > .elCourbaniza {
    position: absolute;
    left: 30px;
    top: 21px;
    width: 155px;
    height: 21px;
  }

.Menumobile .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menumobile .elIconButton:focus {
  outline: none;
}



  .Menumobile > .foreground > .elIconButton {
    position: absolute;
    right: 20px;
    top: 6px;
    width: 56px;
    height: 50px;
  }

.Menumobile > .drawer > .drawerContent {
  right: 0px;
}


/* This component has a fixed-size layout */
  .Selectordrawer {
    min-height: 23px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 230px */
    height: 100%;
  }
  .Selectordrawer > .background > .containerMinHeight {
    min-height: 23px;
  }


  .Selectordrawer > .foreground > .state0_elLanguage {
    overflow: hidden;
    position: absolute;
    left: 30px;
    top: 0px;
    width: 200px;
    height: 33px;
  }


  .Selectordrawer > .foreground > .state0_elEarthglobe1039796 {
    position: absolute;
    left: 5px;
    top: 2px;
    width: 18px;
    height: 18px;
  }

/* This component has a fixed-size layout */
  .Selectordrawer {
    min-height: 23px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 230px */
    height: 100%;
  }
  .Selectordrawer > .background > .containerMinHeight {
    min-height: 23px;
  }


  .Selectordrawer > .foreground > .state1_elLanguage {
    overflow: hidden;
    position: absolute;
    left: 30px;
    top: 0px;
    width: 200px;
    height: 33px;
  }


  .Selectordrawer > .foreground > .state1_elEarthglobe1039796 {
    position: absolute;
    left: 5px;
    top: 2px;
    width: 18px;
    height: 18px;
  }

@keyframes fadeIn_LastquestionScreen_elBgphoto {
  0% { opacity: 0.0; }
  44% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.LastquestionScreen > .background > .elBgphoto {
  animation: 1.80s fadeIn_LastquestionScreen_elBgphoto;
}

/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .background > .elBgphoto {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .LastquestionScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .LastquestionScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .LastquestionScreen > .background > .elBgphoto {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
@media (min-width: 568px) {
  .LastquestionScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }
}
@media (min-width: 768px) {
  .LastquestionScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}
@media (min-width: 1024px) {
  .LastquestionScreen > .background > .elBackground {
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -19.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elText > * { 
  width: 270px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elText > * { 
  width: 270px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elText > * { 
  width: 268px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elText {
    line-height: 24px;
    position: relative;
    margin-top: 60px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elText > * { 
  width: 268px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elProgressBar3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LastquestionScreen > .layoutFlow > .elProgressBar3 > * { 
  width: 280px;
  height: 2px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elProgressBar3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LastquestionScreen > .layoutFlow > .elProgressBar3 > * { 
  width: 280px;
  height: 2px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elProgressBar3 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .LastquestionScreen > .layoutFlow > .elProgressBar3 > * { 
  width: 278px;
  height: 2px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elProgressBar3 {
    position: relative;
    margin-top: 20px;
    width: 278px;
    margin-left: calc(50.0% - 139px);
  }
  .LastquestionScreen > .layoutFlow > .elProgressBar3 > * { 
  width: 278px;
  height: 2px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .LastquestionScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .LastquestionScreen > .layoutFlow > .elText3 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .LastquestionScreen > .layoutFlow > .elText3 > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elText3 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .LastquestionScreen > .layoutFlow > .elText3 > * { 
  width: 237px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elCareabout {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elCareabout > * { 
  width: 270px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elCareabout {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elCareabout > * { 
  width: 270px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elCareabout {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elCareabout > * { 
  width: 268px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elCareabout {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elCareabout > * { 
  width: 268px;
  }
  
}


.LastquestionScreen > .layoutFlow > .elTextareaforcare textarea {
  height: 100%;
}

/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elTextareaforcare {
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elTextareaforcare > * { 
  width: 270px;
  height: 181px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elTextareaforcare {
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elTextareaforcare > * { 
  width: 270px;
  height: 181px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elTextareaforcare {
    position: relative;
    margin-top: 10px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elTextareaforcare > * { 
  width: 268px;
  height: 180px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elTextareaforcare {
    position: relative;
    margin-top: 10px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elTextareaforcare > * { 
  width: 268px;
  height: 180px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elProfessiontext {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elProfessiontext > * { 
  width: 270px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elProfessiontext {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elProfessiontext > * { 
  width: 270px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elProfessiontext {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elProfessiontext > * { 
  width: 268px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elProfessiontext {
    line-height: 24px;
    position: relative;
    margin-top: 20px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elProfessiontext > * { 
  width: 268px;
  }
  
}


.LastquestionScreen > .layoutFlow > .elProfession textarea {
  height: 100%;
}

/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elProfession {
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elProfession > * { 
  width: 270px;
  height: 34px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elProfession {
    position: relative;
    margin-top: 10px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elProfession > * { 
  width: 270px;
  height: 34px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elProfession {
    position: relative;
    margin-top: 10px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elProfession > * { 
  width: 268px;
  height: 34px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elProfession {
    position: relative;
    margin-top: 10px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elProfession > * { 
  width: 268px;
  height: 34px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elValidator > * { 
  width: 270px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 270px;
    margin-left: calc(50.0% - 135px);
  }
  .LastquestionScreen > .layoutFlow > .elValidator > * { 
  width: 270px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elValidator > * { 
  width: 268px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .LastquestionScreen > .layoutFlow > .elValidator > * { 
  width: 268px;
  }
  
}

.LastquestionScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.LastquestionScreen .elButton button:focus {
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .LastquestionScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .LastquestionScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .LastquestionScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .LastquestionScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .LastquestionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .LastquestionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .LastquestionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .LastquestionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .LastquestionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .LastquestionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .LastquestionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  
}

@keyframes fadeIn_LastquestionScreen_elClose {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.LastquestionScreen > .screenFgContainer > .foreground > .elClose {
  animation: 3.00s fadeIn_LastquestionScreen_elClose;
}

/* Narrowest size for this element's responsive layout */
  .LastquestionScreen > .screenFgContainer > .foreground > .elClose {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .LastquestionScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .LastquestionScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .LastquestionScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}

/* This component has a fixed-size layout */
  .Geolocation {
    width: 100%; /* This component was designed using a width of 272px */
    height: 100%;
  }


  .Geolocation > .layoutFlow > .state0_elText837337 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .Geolocation > .layoutFlow > .state0_elText837337 > * { 
  width: 238px;
  }
  

.Geolocation .state0_elCompmyGeo765090 {
}

.Geolocation .state0_elCompmyGeo765090.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Geolocation > .layoutFlow > .state0_elCompmyGeo765090 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Geolocation > .layoutFlow > .state0_elCompmyGeo765090 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .flowRow > .state0_elTextCopy300259 {
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    width: 200px;
    margin-left: 48px;
  }
  .Geolocation > .layoutFlow > .flowRow > .state0_elTextCopy300259 > * { 
  width: 200px;
  }
  
  .flowRow_Geolocation_state0_elTextCopy300259_300259 { 
  }
  


  .Geolocation > .layoutFlow > .state0_elMapgeo792794 {
    position: relative;
    margin-top: 10px;
    width: 28px;
    margin-left: 12px;
  }
  .Geolocation > .layoutFlow > .state0_elMapgeo792794 > * { 
  width: 28px;
  height: 26px;
  }
  


  .Geolocation > .layoutFlow > .state0_elText2882654 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Geolocation > .layoutFlow > .state0_elText2882654 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .state0_elField52603 {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Geolocation > .layoutFlow > .state0_elField52603 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .state0_elSpacer1513867061 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Geolocation > .layoutFlow > .state0_elSpacer1513867061 > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Geolocation {
    width: 100%; /* This component was designed using a width of 272px */
    height: 100%;
  }


  .Geolocation > .layoutFlow > .state1_elText837337 {
    line-height: 24px;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .Geolocation > .layoutFlow > .state1_elText837337 > * { 
  width: 238px;
  }
  

.Geolocation .state1_elCompmyGeo765090 {
}

.Geolocation .state1_elCompmyGeo765090.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Geolocation > .layoutFlow > .state1_elCompmyGeo765090 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Geolocation > .layoutFlow > .state1_elCompmyGeo765090 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .flowRow > .state1_elTextCopy300259 {
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    width: 200px;
    margin-left: 48px;
  }
  .Geolocation > .layoutFlow > .flowRow > .state1_elTextCopy300259 > * { 
  width: 200px;
  }
  
  .flowRow_Geolocation_state1_elTextCopy300259_300259 { 
  }
  


  .Geolocation > .layoutFlow > .state1_elMapgeo792794 {
    position: relative;
    margin-top: 10px;
    width: 28px;
    margin-left: 12px;
  }
  .Geolocation > .layoutFlow > .state1_elMapgeo792794 > * { 
  width: 28px;
  height: 26px;
  }
  


  .Geolocation > .layoutFlow > .state1_elText2882654 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Geolocation > .layoutFlow > .state1_elText2882654 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .state1_elField52603 {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Geolocation > .layoutFlow > .state1_elField52603 > * { 
    width: 100%;
  }
  


  .Geolocation > .layoutFlow > .state1_elSpacer1513867061 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Geolocation > .layoutFlow > .state1_elSpacer1513867061 > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .LoadingfeedScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .LoadingfeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .LoadingfeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .LoadingfeedScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoadingfeedScreen > .screenFgContainer > .foreground > .elPreloader {
    position: absolute;
    left: calc(50.0% + 0px - 62px);
    top: 230px;
    width: 124px;
    height: 65px;
  }
@media (min-width: 568px) {
  .LoadingfeedScreen > .screenFgContainer > .foreground > .elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 230px;
    width: 124px;
    height: 65px;
  }
}
@media (min-width: 768px) {
  .LoadingfeedScreen > .screenFgContainer > .foreground > .elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 229px;
    width: 123px;
    height: 64px;
  }
}
@media (min-width: 1024px) {
  .LoadingfeedScreen > .screenFgContainer > .foreground > .elPreloader {
    left: calc(50.0% + 0px - 62px);
    top: 229px;
    width: 123px;
    height: 64px;
  }
}

/* This component has a fixed-size layout */
  .Price1 {
    min-height: 400px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Price1 > .background > .containerMinHeight {
    min-height: 400px;
  }


  .Price1 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Price1 > .layoutFlow > .elPlanName {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price1 > .layoutFlow > .elPlanName > * { 
  width: 280px;
  }
  


  .Price1 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price1 > .layoutFlow > .elText > * { 
  width: 280px;
  }
  


  .Price1 > .layoutFlow > .elPrice {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price1 > .layoutFlow > .elPrice > * { 
  width: 280px;
  }
  


  .Price1 > .layoutFlow > .elFeatures {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price1 > .layoutFlow > .elFeatures > * { 
  width: 280px;
  }
  

.Price1 .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Price1 .elButton button:focus {
  outline: none;
}



  .Price1 > .layoutFlow > .elButton {
    line-height: 34px;
    position: relative;
    margin-top: 60px;
    width: 165px;
    margin-left: calc(50.0% - 82px);
  }
  .Price1 > .layoutFlow > .elButton > * { 
  width: 165px;
  height: 34px;
  }
  


  .Price1 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Price1 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .Price2 {
    min-height: 400px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Price2 > .background > .containerMinHeight {
    min-height: 400px;
  }


  .Price2 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Price2 > .layoutFlow > .elPlanName {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price2 > .layoutFlow > .elPlanName > * { 
  width: 280px;
  }
  


  .Price2 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price2 > .layoutFlow > .elText > * { 
  width: 280px;
  }
  


  .Price2 > .layoutFlow > .elPrice {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .Price2 > .layoutFlow > .elPrice > * { 
  width: 238px;
  }
  


  .Price2 > .layoutFlow > .elFeatures {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price2 > .layoutFlow > .elFeatures > * { 
  width: 280px;
  }
  

.Price2 .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Price2 .elButton button:focus {
  outline: none;
}



  .Price2 > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 165px;
    margin-left: calc(50.0% - 82px);
  }
  .Price2 > .layoutFlow > .elButton > * { 
  width: 165px;
  height: 35px;
  }
  


  .Price2 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Price2 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .Price3 {
    min-height: 400px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Price3 > .background > .containerMinHeight {
    min-height: 400px;
  }


  .Price3 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Price3 > .layoutFlow > .elPlanName {
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    width: 290px;
    margin-left: calc(50.0% - 145px);
  }
  .Price3 > .layoutFlow > .elPlanName > * { 
  width: 290px;
  }
  


  .Price3 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 260px;
    margin-left: calc(50.0% - 130px);
  }
  .Price3 > .layoutFlow > .elText > * { 
  width: 260px;
  }
  


  .Price3 > .layoutFlow > .elPrice {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .Price3 > .layoutFlow > .elPrice > * { 
  width: 238px;
  }
  


  .Price3 > .layoutFlow > .elFeatures {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .Price3 > .layoutFlow > .elFeatures > * { 
  width: 280px;
  }
  

.Price3 .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Price3 .elButton button:focus {
  outline: none;
}



  .Price3 > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 165px;
    margin-left: calc(50.0% - 82px);
  }
  .Price3 > .layoutFlow > .elButton > * { 
  width: 165px;
  height: 35px;
  }
  


  .Price3 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Price3 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  


/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Collaborative_mapScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.Collaborative_mapScreen .state0_elCompMap {
  pointer-events: auto;
}

.Collaborative_mapScreen .state0_elCompMap.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .background > .state0_elCompMap {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 100px;
    width: 100.0%;
    height: calc(100% + 0.0% + -170.0px);
  }
  .Collaborative_mapScreen > .background > .state0_elCompMap > .background > .containerMinHeight {
    min-height: 378px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .background > .state0_elCompMap {
    left: 0px;
    right: 0px;
    top: 100px;
    width: 100.0%;
    height: calc(100% + 0.0% + -170.0px);
  }
  .Collaborative_mapScreen > .background > .state0_elCompMap > .background > .containerMinHeight {
    min-height: 378px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .background > .state0_elCompMap {
    left: 0px;
    right: 0px;
    top: 99px;
    width: 100.0%;
    height: calc(100% + 0.0% + -168.9px);
  }
  .Collaborative_mapScreen > .background > .state0_elCompMap > .background > .containerMinHeight {
    min-height: 378px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .background > .state0_elCompMap {
    left: 0px;
    right: 0px;
    top: 99px;
    width: 100.0%;
    height: calc(100% + 0.0% + -168.9px);
  }
  .Collaborative_mapScreen > .background > .state0_elCompMap > .background > .containerMinHeight {
    min-height: 379px;
  }

}

.Collaborative_mapScreen .state0_elColorslabels {
}

.Collaborative_mapScreen .state0_elColorslabels.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_Collaborative_mapScreen_state0_elColorslabels {
  0% { opacity: 0.0; }
  80% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Collaborative_mapScreen > .background > .state0_elColorslabels {
  animation: 1.25s fadeIn_Collaborative_mapScreen_state0_elColorslabels;
}

/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .background > .state0_elColorslabels {
    position: fixed;
    left: 20px;
    bottom: 170px;
    width: 180px;
    height: 180px;
  }
  .Collaborative_mapScreen > .background > .state0_elColorslabels > .background > .containerMinHeight {
    min-height: 180px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .background > .state0_elColorslabels {
    left: 20px;
    bottom: 170px;
    width: 180px;
    height: 180px;
  }
  .Collaborative_mapScreen > .background > .state0_elColorslabels > .background > .containerMinHeight {
    min-height: 180px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .background > .state0_elColorslabels {
    left: 20px;
    bottom: 169px;
    width: 179px;
    height: 179px;
  }
  .Collaborative_mapScreen > .background > .state0_elColorslabels > .background > .containerMinHeight {
    min-height: 179px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .background > .state0_elColorslabels {
    left: 20px;
    bottom: 169px;
    width: 179px;
    height: 179px;
  }
  .Collaborative_mapScreen > .background > .state0_elColorslabels > .background > .containerMinHeight {
    min-height: 179px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 170px;
    margin-left: 20px;
  }
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle > * { 
  width: 170px;
  }
  
@media (min-width: 568px) {
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 170px;
    margin-left: 20px;
  }
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle > * { 
  width: 170px;
  }
  
}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle {
    overflow: hidden;
    position: relative;
    margin-top: 90px;
    width: 169px;
    margin-left: 20px;
  }
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle > * { 
  width: 169px;
  }
  
}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 169px;
    margin-left: 20px;
  }
  .Collaborative_mapScreen > .layoutFlow > .state0_elDhashboardtitle > * { 
  width: 169px;
  }
  
}

.Collaborative_mapScreen .state0_elOpenlanguageSelector {
}

.Collaborative_mapScreen .state0_elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}

.Collaborative_mapScreen .state0_elComplegend {
}

.Collaborative_mapScreen .state0_elComplegend.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_Collaborative_mapScreen_state0_elComplegend {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend {
  animation: 3.25s fadeIn_Collaborative_mapScreen_state0_elComplegend;
}

/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend {
    position: fixed;
    left: 20px;
    bottom: 120px;
    width: 102px;
    height: 30px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend {
    left: 20px;
    bottom: 120px;
    width: 102px;
    height: 30px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend {
    left: 20px;
    bottom: 119px;
    width: 101px;
    height: 30px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend {
    left: 20px;
    bottom: 119px;
    width: 101px;
    height: 30px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elComplegend > .background > .containerMinHeight {
    min-height: 30px;
  }

}

.Collaborative_mapScreen .state0_elCompbg {
}

.Collaborative_mapScreen .state0_elCompbg.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: calc(100% + 0.0% + -1.0px);
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}

.Collaborative_mapScreen .state0_elMenucreate {
}

.Collaborative_mapScreen .state0_elMenucreate.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate {
    position: fixed;
    left: calc(50.0% + 0px - 61px);
    bottom: 85px;
    width: 122px;
    height: 215px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate > .background > .containerMinHeight {
    min-height: 215px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 85px;
    width: 122px;
    height: 215px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate > .background > .containerMinHeight {
    min-height: 215px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 84px;
    width: 121px;
    height: 214px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate > .background > .containerMinHeight {
    min-height: 214px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate {
    left: calc(50.0% + 0px - 61px);
    bottom: 84px;
    width: 121px;
    height: 214px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elMenucreate > .background > .containerMinHeight {
    min-height: 214px;
  }

}

.Collaborative_mapScreen .state0_elCompnafooter {
  pointer-events: auto;
}

.Collaborative_mapScreen .state0_elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .Collaborative_mapScreen > .screenFgContainer > .foreground > .state0_elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}

/* This component has a fixed-size layout */
  .Listprojectmap {
    min-width: 261px; /* This component has a fixed size */
  }


  .Listprojectmap > .layoutFlow > .flowRow > .elDocid {
    overflow: hidden;
    position: absolute;
    margin-top: 0px;
    width: 120px;
    margin-left: 140px;
  }
  .Listprojectmap > .layoutFlow > .flowRow > .elDocid > * { 
  width: 120px;
  }
  
  .flowRow_Listprojectmap_elDocid_109705 { 
  }
  


  .Listprojectmap > .layoutFlow > .elQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Listprojectmap > .layoutFlow > .elQuestion > * { 
  width: 120px;
  }
  


  .Listprojectmap > .layoutFlow > .elProjectType {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Listprojectmap > .layoutFlow > .elProjectType > * { 
  width: 120px;
  }
  


  .Listprojectmap > .layoutFlow > .elPollQuestion {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Listprojectmap > .layoutFlow > .elPollQuestion > * { 
  width: 120px;
  }
  

/* This component has a fixed-size layout */
  .Menucreate {
    width: 100%; /* This component was designed using a width of 123px */
    height: 100%;
  }

.Menucreate .elButtonNewProject button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menucreate .elButtonNewProject button:focus {
  outline: none;
}



  .Menucreate > .layoutFlow > .elButtonNewProject {
    line-height: 35px;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Menucreate > .layoutFlow > .elButtonNewProject > * { 
    width: 100%;
  height: 35px;
  }
  

.Menucreate .elButtonPoll button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menucreate .elButtonPoll button:focus {
  outline: none;
}



  .Menucreate > .layoutFlow > .elButtonPoll {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Menucreate > .layoutFlow > .elButtonPoll > * { 
    width: 100%;
  height: 35px;
  }
  

.Menucreate .elButtonCocreation button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menucreate .elButtonCocreation button:focus {
  outline: none;
}



  .Menucreate > .layoutFlow > .elButtonCocreation {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Menucreate > .layoutFlow > .elButtonCocreation > * { 
    width: 100%;
  height: 35px;
  }
  

.Menucreate .elButtonReport button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menucreate .elButtonReport button:focus {
  outline: none;
}



  .Menucreate > .layoutFlow > .elButtonReport {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Menucreate > .layoutFlow > .elButtonReport > * { 
    width: 100%;
  height: 35px;
  }
  

.Menucreate .elButtonodeia button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menucreate .elButtonodeia button:focus {
  outline: none;
}



  .Menucreate > .layoutFlow > .elButtonodeia {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Menucreate > .layoutFlow > .elButtonodeia > * { 
    width: 100%;
  height: 35px;
  }
  

/* This component has a fixed-size layout */
  .RequestpartnershiptForm {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .RequestpartnershiptForm > .layoutFlow > .state0_elTextRDemo {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elTextRDemo > * { 
  width: 238px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elTxttakeamoment {
    line-height: 16px;
    position: relative;
    margin-top: 9px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elTxttakeamoment > * { 
  width: 238px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elName {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elName > * { 
    width: 100%;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elEmail {
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elEmail > * { 
    width: 100%;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elOrganization {
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elOrganization > * { 
    width: 100%;
  }
  


.RequestpartnershiptForm > .layoutFlow > .state0_elMessage textarea {
  height: 100%;
}

  .RequestpartnershiptForm > .layoutFlow > .state0_elMessage {
    position: relative;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elMessage > * { 
    width: 100%;
  height: 90px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elOrgtypetxt {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elOrgtypetxt > * { 
  width: 238px;
  }
  

.RequestpartnershiptForm .state0_elComp {
}

.RequestpartnershiptForm .state0_elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .RequestpartnershiptForm > .layoutFlow > .state0_elComp {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elComp > * { 
  width: 181px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 14px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elValidator > * { 
  width: 238px;
  }
  

.RequestpartnershiptForm .state0_elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RequestpartnershiptForm .state0_elButton button:focus {
  outline: none;
}



  .RequestpartnershiptForm > .layoutFlow > .state0_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 100%;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elButton > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 20px;
  height: 35px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state0_elSpacer > * { 
  width: 10px;
  height: 70px;
  }
  


  .RequestpartnershiptForm > .foreground > .state0_elClose592589 {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .RequestpartnershiptForm {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .RequestpartnershiptForm > .layoutFlow > .state1_elTextRDemo {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state1_elTextRDemo > * { 
  width: 238px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state1_elTxttakeamoment {
    line-height: 16px;
    position: relative;
    margin-top: 9px;
    width: 238px;
    margin-left: 20px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state1_elTxttakeamoment > * { 
  width: 238px;
  }
  

.RequestpartnershiptForm .state1_elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RequestpartnershiptForm .state1_elButton button:focus {
  outline: none;
}



  .RequestpartnershiptForm > .layoutFlow > .state1_elButton {
    line-height: 35px;
    position: relative;
    margin-top: 246px;
    width: 100%;
  }
  .RequestpartnershiptForm > .layoutFlow > .state1_elButton > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 20px;
  height: 35px;
  }
  


  .RequestpartnershiptForm > .layoutFlow > .state1_elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 140px;
  }
  .RequestpartnershiptForm > .layoutFlow > .state1_elSpacer2 > * { 
  width: 10px;
  height: 40px;
  }
  


  .RequestpartnershiptForm > .foreground > .state1_elClose592589 {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
  }


/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .background > .elNeighsomerville {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .RequestpartnershipScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .background > .elNeighsomerville {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.RequestpartnershipScreen .elPartnershipcontactForm {
}

.RequestpartnershipScreen .elPartnershipcontactForm.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm {
    position: relative;
    margin-top: 80px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > * { 
  width: 300px;
  }
  
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > .background > .containerMinHeight {
    min-height: 560px;
  }

@media (min-width: 568px) {
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm {
    position: relative;
    margin-top: 80px;
    width: 300px;
    margin-left: calc(50.0% - 150px);
  }
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > * { 
  width: 300px;
  }
  
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > .background > .containerMinHeight {
    min-height: 560px;
  }

}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm {
    position: relative;
    margin-top: 80px;
    width: 420px;
    margin-left: calc(50.0% - 210px);
  }
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > * { 
  width: 420px;
  }
  
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > .background > .containerMinHeight {
    min-height: 560px;
  }

}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm {
    position: relative;
    margin-top: 80px;
    width: 460px;
    margin-left: calc(50.0% - 230px);
  }
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > * { 
  width: 460px;
  }
  
  .RequestpartnershipScreen > .layoutFlow > .elPartnershipcontactForm > .background > .containerMinHeight {
    min-height: 560px;
  }

}


/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .RequestpartnershipScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .RequestpartnershipScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .RequestpartnershipScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .RequestpartnershipScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .RequestpartnershipScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elBarfooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 30px;
  }
@media (min-width: 568px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elBarfooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elBarfooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elBarfooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 30px;
  }
}

.RequestpartnershipScreen .elFooter {
}

.RequestpartnershipScreen .elFooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 5px;
    width: 100.0%;
    height: 20px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter > .background > .containerMinHeight {
    min-height: 20px;
  }

@media (min-width: 568px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter {
    left: 0px;
    right: 0px;
    bottom: 5px;
    width: 100.0%;
    height: 20px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter > .background > .containerMinHeight {
    min-height: 21px;
  }

}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter {
    left: 0px;
    right: 0px;
    bottom: 5px;
    width: 100.0%;
    height: 20px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter > .background > .containerMinHeight {
    min-height: 20px;
  }

}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter {
    left: 0px;
    right: 0px;
    bottom: 7px;
    width: 100.0%;
    height: 20px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elFooter > .background > .containerMinHeight {
    min-height: 20px;
  }

}

.RequestpartnershipScreen .elOpenlanguageSelector {
}

.RequestpartnershipScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 30px;
    top: 65px;
    width: 100px;
    height: 70px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 100px;
    height: 70px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 99px;
    height: 70px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 30px;
    top: 65px;
    width: 99px;
    height: 70px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .RequestpartnershipScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}

/* This component has a fixed-size layout */
  .Comp2 {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Comp2 > .layoutFlow > .state0_elTypeorganpicker {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: 0px;
  }
  .Comp2 > .layoutFlow > .state0_elTypeorganpicker > * { 
  width: 181px;
  }
  

/* This component has a fixed-size layout */
  .Comp2 {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Comp2 > .layoutFlow > .state1_elTypeorganpicker {
    position: relative;
    margin-top: 0px;
    width: 181px;
    margin-left: 0px;
  }
  .Comp2 > .layoutFlow > .state1_elTypeorganpicker > * { 
  width: 181px;
  }
  

/* This component has a fixed-size layout */
  .Compnavfotterh {
    min-height: 71px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }
  .Compnavfotterh > .background > .containerMinHeight {
    min-height: 71px;
  }


  .Compnavfotterh > .foreground > .state0_elLine365203 {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 65px;
  }


  .Compnavfotterh > .foreground > .state0_elBarfooter275208 {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 64px;
  }


  .Compnavfotterh > .foreground > .state0_elMapnav861227 {
    position: absolute;
    left: calc(50.0% + 120px - 16px);
    top: 11px;
    width: 32px;
    height: 31px;
  }


  .Compnavfotterh > .foreground > .state0_elTimelinenav759849 {
    position: absolute;
    left: calc(50.0% + -120px - 18px);
    top: 11px;
    width: 36px;
    height: 31px;
  }

.Compnavfotterh .state0_elHotspotmap885233 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state0_elHotspotmap885233 {
    position: absolute;
    left: calc(50.0% + 120px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }

.Compnavfotterh .state0_elHotspotfeed270744 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state0_elHotspotfeed270744 {
    position: absolute;
    left: calc(50.0% + -120px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }

.Compnavfotterh .state0_elFab995410 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Compnavfotterh .state0_elFab995410:focus {
  outline: none;
}



  .Compnavfotterh > .foreground > .state0_elFab995410 {
    line-height: 40px;
    position: absolute;
    left: calc(50.0% + 0px - 20px);
    top: 2px;
    width: 40px;
    height: 40px;
  }

.Compnavfotterh .state0_elHotspotCreate {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state0_elHotspotCreate {
    position: absolute;
    left: calc(50.0% + 0px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }

/* This component has a fixed-size layout */
  .Compnavfotterh {
    min-height: 71px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }
  .Compnavfotterh > .background > .containerMinHeight {
    min-height: 71px;
  }


  .Compnavfotterh > .foreground > .state1_elLine365203 {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 65px;
  }


  .Compnavfotterh > .foreground > .state1_elBarfooter275208 {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 64px;
  }


  .Compnavfotterh > .foreground > .state1_elMapnav861227 {
    position: absolute;
    left: calc(50.0% + 120px - 16px);
    top: 11px;
    width: 32px;
    height: 31px;
  }


  .Compnavfotterh > .foreground > .state1_elTimelinenav759849 {
    position: absolute;
    left: calc(50.0% + -120px - 18px);
    top: 11px;
    width: 36px;
    height: 31px;
  }

.Compnavfotterh .state1_elHotspotmap885233 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state1_elHotspotmap885233 {
    position: absolute;
    left: calc(50.0% + 120px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }

.Compnavfotterh .state1_elHotspotfeed270744 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state1_elHotspotfeed270744 {
    position: absolute;
    left: calc(50.0% + -120px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }

.Compnavfotterh .state1_elFab995410 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Compnavfotterh .state1_elFab995410:focus {
  outline: none;
}



  .Compnavfotterh > .foreground > .state1_elFab995410 {
    line-height: 40px;
    position: absolute;
    left: calc(50.0% + 0px - 20px);
    top: 2px;
    width: 40px;
    height: 40px;
  }

.Compnavfotterh .state1_elHotspotCreate {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Compnavfotterh > .foreground > .state1_elHotspotCreate {
    position: absolute;
    left: calc(50.0% + 0px - 40px);
    top: 0px;
    width: 80px;
    height: 60px;
  }


/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .NewProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .NewProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .NewProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.NewProjectScreen .elFormpostproject {
}

.NewProjectScreen .elFormpostproject.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .layoutFlow > .elFormpostproject {
    position: relative;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .NewProjectScreen > .layoutFlow > .elFormpostproject > * { 
    width: 100%;
  }
  
  .NewProjectScreen > .layoutFlow > .elFormpostproject > .background > .containerMinHeight {
    min-height: 1155px;
  }

@media (min-width: 568px) {
  .NewProjectScreen > .layoutFlow > .elFormpostproject {
    position: relative;
    margin-top: 60px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .NewProjectScreen > .layoutFlow > .elFormpostproject > * { 
    width: 100%;
  }
  
  .NewProjectScreen > .layoutFlow > .elFormpostproject > .background > .containerMinHeight {
    min-height: 1155px;
  }

}
@media (min-width: 768px) {
  .NewProjectScreen > .layoutFlow > .elFormpostproject {
    position: relative;
    margin-top: 60px;
    margin-left: 240px;
    margin-right: 20px;
    width: calc(100.0% - 260px);
  }
  .NewProjectScreen > .layoutFlow > .elFormpostproject > * { 
    width: 100%;
  }
  
  .NewProjectScreen > .layoutFlow > .elFormpostproject > .background > .containerMinHeight {
    min-height: 1155px;
  }

}
@media (min-width: 1024px) {
  .NewProjectScreen > .layoutFlow > .elFormpostproject {
    position: relative;
    margin-top: 77px;
    margin-left: 240px;
    margin-right: 20px;
    width: calc(100.0% - 260px);
  }
  .NewProjectScreen > .layoutFlow > .elFormpostproject > * { 
    width: 100%;
  }
  
  .NewProjectScreen > .layoutFlow > .elFormpostproject > .background > .containerMinHeight {
    min-height: 1155px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    position: absolute;
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

@media (min-width: 568px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

}
@media (min-width: 768px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 500px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}
@media (min-width: 1024px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 430px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .screenFgContainer > .foreground > .elRect {
    position: absolute;
    left: -50px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
@media (min-width: 568px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 29px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1024px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}

.NewProjectScreen .elOpenlanguageSelector {
}

.NewProjectScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}

.NewProjectScreen .elModelEditonMap {
}

.NewProjectScreen .elModelEditonMap.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

@media (min-width: 568px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 768px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 1024px) {
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .NewProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewPollScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .NewPollScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .NewPollScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .NewPollScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .NewPollScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 150px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .NewPollScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .NewPollScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 150px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .NewPollScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .NewPollScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 149px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .NewPollScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .NewPollScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 149px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .NewPollScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}

.NewPollScreen .elCompnafooter {
  pointer-events: auto;
}

.NewPollScreen .elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}

.NewPollScreen .elOpenlanguageSelector {
}

.NewPollScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewPollScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewQuestionScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .NewQuestionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .NewQuestionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .NewQuestionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .NewQuestionScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 150px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .NewQuestionScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .NewQuestionScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 150px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .NewQuestionScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .NewQuestionScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 149px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .NewQuestionScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}
@media (min-width: 1024px) {
  .NewQuestionScreen > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 149px;
    width: 237px;
    margin-left: calc(50.0% - 118px);
  }
  .NewQuestionScreen > .layoutFlow > .elText > * { 
  width: 237px;
  }
  
}

.NewQuestionScreen .elCompnafooter {
  pointer-events: auto;
}

.NewQuestionScreen .elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}

.NewQuestionScreen .elOpenlanguageSelector {
}

.NewQuestionScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}


/* Narrowest size for this element's responsive layout */
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .NewQuestionScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}

/* This component has a fixed-size layout */
  .Compbg2 {
    min-height: 40px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 280px */
    height: 100%;
  }
  .Compbg2 > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Compbg2 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Compbg2 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Compbg2 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Component1home {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Component1home > .layoutFlow > .elTextCopy2 {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Component1home > .layoutFlow > .elTextCopy2 > * { 
    width: 100%;
  }
  


  .Component1home > .layoutFlow > .elText {
    line-height: 22px;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Component1home > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .Component1home > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Component1home > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Comp6 {
    min-height: 59px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 322px; /* This component has a fixed size */
  }
  .Comp6 > .background > .containerMinHeight {
    min-height: 59px;
  }


  .Comp6 > .foreground > .elAddress {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 58px;
  }

/* This component has a fixed-size layout */
  .Backgroundtrans {
    min-height: 569px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }
  .Backgroundtrans > .background > .containerMinHeight {
    min-height: 569px;
  }


  .Backgroundtrans > .foreground > .elBgmodal {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Colorslabels {
    min-height: 180px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 180px */
    height: 100%;
  }
  .Colorslabels > .background > .containerMinHeight {
    min-height: 180px;
  }


  .Colorslabels > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Colorslabels > .layoutFlow > .flowRow > .elTextproject {
    overflow: hidden;
    position: absolute;
    margin-top: 20px;
    width: 120px;
    margin-left: 50px;
  }
  .Colorslabels > .layoutFlow > .flowRow > .elTextproject > * { 
  width: 120px;
  }
  
  .flowRow_Colorslabels_elTextproject_153731 { 
  }
  


  .Colorslabels > .layoutFlow > .elColorprojects {
    position: relative;
    margin-top: 20px;
    width: 20px;
    margin-left: 15px;
  }
  .Colorslabels > .layoutFlow > .elColorprojects > * { 
  width: 20px;
  height: 20px;
  }
  


  .Colorslabels > .layoutFlow > .flowRow > .elTextquestions {
    overflow: hidden;
    position: absolute;
    margin-top: 13px;
    width: 120px;
    margin-left: 50px;
  }
  .Colorslabels > .layoutFlow > .flowRow > .elTextquestions > * { 
  width: 120px;
  }
  
  .flowRow_Colorslabels_elTextquestions_805128 { 
  }
  


  .Colorslabels > .layoutFlow > .elColorquestions {
    position: relative;
    margin-top: 13px;
    width: 20px;
    margin-left: 15px;
  }
  .Colorslabels > .layoutFlow > .elColorquestions > * { 
  width: 20px;
  height: 20px;
  }
  


  .Colorslabels > .layoutFlow > .flowRow > .elTextpolls {
    overflow: hidden;
    position: absolute;
    margin-top: 13px;
    width: 120px;
    margin-left: 50px;
  }
  .Colorslabels > .layoutFlow > .flowRow > .elTextpolls > * { 
  width: 120px;
  }
  
  .flowRow_Colorslabels_elTextpolls_59314 { 
  }
  


  .Colorslabels > .layoutFlow > .elColorpolls {
    position: relative;
    margin-top: 13px;
    width: 20px;
    margin-left: 15px;
  }
  .Colorslabels > .layoutFlow > .elColorpolls > * { 
  width: 20px;
  height: 20px;
  }
  


  .Colorslabels > .layoutFlow > .flowRow > .elTextideas {
    overflow: hidden;
    position: absolute;
    margin-top: 13px;
    width: 120px;
    margin-left: 50px;
  }
  .Colorslabels > .layoutFlow > .flowRow > .elTextideas > * { 
  width: 120px;
  }
  
  .flowRow_Colorslabels_elTextideas_814310 { 
  }
  


  .Colorslabels > .layoutFlow > .elColorideas {
    position: relative;
    margin-top: 13px;
    width: 20px;
    margin-left: 15px;
  }
  .Colorslabels > .layoutFlow > .elColorideas > * { 
  width: 20px;
  height: 20px;
  }
  


  .Colorslabels > .layoutFlow > .elTextBlock {
    overflow: hidden;
    position: relative;
    margin-top: 13px;
    width: 100%;
  }
  .Colorslabels > .layoutFlow > .elTextBlock > * { 
  margin-left: auto;
  width: 47px;
  margin-right: 20px;
  }
  

/* This component has a fixed-size layout */
  .Complegend {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 100px */
    height: 100%;
  }
  .Complegend > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Complegend > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Complegend > .foreground > .elMaplegend {
    overflow: hidden;
    position: absolute;
    left: 7px;
    bottom: 7px;
    width: 90px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .CompMapCreate {
    min-width: 320px; /* This component has a fixed size */
  }


  .CompMapCreate > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 200px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .CompMapCreate > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  


  .CompMapCreate > .foreground > .elMapembed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.CompMapCreate .elBgcomp1 {
}

.CompMapCreate .elBgcomp1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_CompMapCreate_elBgcomp1 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elBgcomp1 {
  animation: 4.00s fadeIn_CompMapCreate_elBgcomp1;
}

  .CompMapCreate > .foreground > .elBgcomp1 {
    position: absolute;
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }

.CompMapCreate .elBgcomp2 {
}

.CompMapCreate .elBgcomp2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_CompMapCreate_elBgcomp2 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elBgcomp2 {
  animation: 4.00s fadeIn_CompMapCreate_elBgcomp2;
}

  .CompMapCreate > .foreground > .elBgcomp2 {
    position: absolute;
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }

.CompMapCreate .elButtonset {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CompMapCreate .elButtonset:focus {
  outline: none;
}


@keyframes fadeIn_CompMapCreate_elButtonset {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elButtonset {
  animation: 3.25s fadeIn_CompMapCreate_elButtonset;
}

  .CompMapCreate > .foreground > .elButtonset {
    line-height: 35px;
    position: absolute;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }

@keyframes fadeIn_CompMapCreate_elGuide {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elGuide {
  animation: 4.00s fadeIn_CompMapCreate_elGuide;
}

  .CompMapCreate > .foreground > .elGuide {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }

@keyframes fadeIn_CompMapCreate_elLtlng {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elLtlng {
  animation: 4.00s fadeIn_CompMapCreate_elLtlng;
}

  .CompMapCreate > .foreground > .elLtlng {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }

@keyframes fadeIn_CompMapCreate_elClose {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMapCreate > .foreground > .elClose {
  animation: 3.00s fadeIn_CompMapCreate_elClose;
}

  .CompMapCreate > .foreground > .elClose {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }


/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 200px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .CreateonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .CreateonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 200px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .CreateonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .CreateonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 199px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .CreateonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .layoutFlow > .elProgress {
    position: relative;
    margin-top: 199px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .CreateonmapScreen > .layoutFlow > .elProgress > * { 
  width: 48px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: calc(100% + 0.0% + 200.0px);
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elMapembed {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: calc(100% + 0.0% + 200.0px);
  }
}

.CreateonmapScreen .elButtonset {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CreateonmapScreen .elButtonset:focus {
  outline: none;
}


@keyframes fadeIn_CreateonmapScreen_elButtonset {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elButtonset {
  animation: 3.25s fadeIn_CreateonmapScreen_elButtonset;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    position: fixed;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 159px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elButtonset {
    line-height: 35px;
    right: 70px;
    bottom: 60px;
    width: 159px;
    height: 35px;
  }
}

.CreateonmapScreen .elBgcomp2 {
}

.CreateonmapScreen .elBgcomp2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_CreateonmapScreen_elBgcomp2 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
  animation: 4.00s fadeIn_CreateonmapScreen_elBgcomp2;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    position: absolute;
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 135px;
    width: 298px;
    height: 50px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 {
    left: 10px;
    top: 135px;
    width: 298px;
    height: 50px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp2 > .background > .containerMinHeight {
    min-height: 40px;
  }

}

.CreateonmapScreen .elBgcomp1 {
}

.CreateonmapScreen .elBgcomp1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_CreateonmapScreen_elBgcomp1 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
  animation: 4.00s fadeIn_CreateonmapScreen_elBgcomp1;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    position: absolute;
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 298px;
    height: 99px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 {
    left: 10px;
    top: 27px;
    width: 298px;
    height: 99px;
  }
  .CreateonmapScreen > .screenFgContainer > .foreground > .elBgcomp1 > .background > .containerMinHeight {
    min-height: 40px;
  }

}

@keyframes fadeIn_CreateonmapScreen_elLtlng {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elLtlng {
  animation: 4.00s fadeIn_CreateonmapScreen_elLtlng;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 278px;
    height: 83px;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elLtlng {
    overflow: hidden;
    left: 17px;
    top: 34px;
    width: 278px;
    height: 83px;
  }
}

@keyframes fadeIn_CreateonmapScreen_elGuide {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elGuide {
  animation: 4.00s fadeIn_CreateonmapScreen_elGuide;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 147px;
    width: 278px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elGuide {
    overflow: hidden;
    left: 17px;
    top: 147px;
    width: 278px;
    height: 35px;
  }
}

@keyframes fadeIn_CreateonmapScreen_elClose {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elClose {
  animation: 3.00s fadeIn_CreateonmapScreen_elClose;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elClose {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elClose {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}

@keyframes fadeIn_CreateonmapScreen_elCloseCopy {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CreateonmapScreen > .screenFgContainer > .foreground > .elCloseCopy {
  animation: 3.00s fadeIn_CreateonmapScreen_elCloseCopy;
}

/* Narrowest size for this element's responsive layout */
  .CreateonmapScreen > .screenFgContainer > .foreground > .elCloseCopy {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elCloseCopy {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elCloseCopy {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .CreateonmapScreen > .screenFgContainer > .foreground > .elCloseCopy {
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }
}

/* This component has a fixed-size layout */
  .Menulogout {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .Menulogout > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menulogout > .background > .state0_elBackground15030526 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menulogout > .layoutFlow > .flowRow > .state0_elContato1713573071 {
    overflow: hidden;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Menulogout > .layoutFlow > .flowRow > .state0_elContato1713573071 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 138px;
  }
  
  .flowRow_Menulogout_state0_elContato1713573071_1713573071 { 
  }
  

.Menulogout .state0_elSelector306729510 {
}

.Menulogout .state0_elSelector306729510.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menulogout > .layoutFlow > .state0_elSelector306729510 {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Menulogout > .layoutFlow > .state0_elSelector306729510 > * { 
  margin-left: auto;
  width: 110px;
  margin-right: 20px;
  }
  


  .Menulogout > .foreground > .state0_elRect2009086594 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }


  .Menulogout > .foreground > .state0_elCourbaniza {
    position: absolute;
    left: 30px;
    top: 21px;
    width: 155px;
    height: 21px;
  }

/* This component has a fixed-size layout */
  .Menulogout {
    min-height: 60px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 800px */
    height: 100%;
  }
  .Menulogout > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menulogout > .background > .state1_elBackground15030526 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menulogout > .layoutFlow > .flowRow > .state1_elContato1713573071 {
    overflow: hidden;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Menulogout > .layoutFlow > .flowRow > .state1_elContato1713573071 > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 138px;
  }
  
  .flowRow_Menulogout_state1_elContato1713573071_1713573071 { 
  }
  

.Menulogout .state1_elSelector306729510 {
}

.Menulogout .state1_elSelector306729510.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menulogout > .layoutFlow > .state1_elSelector306729510 {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Menulogout > .layoutFlow > .state1_elSelector306729510 > * { 
  margin-left: auto;
  width: 110px;
  margin-right: 20px;
  }
  


  .Menulogout > .foreground > .state1_elRect2009086594 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }

/* This component has a fixed-size layout */
  .Menumobilelogout {
    min-height: 60px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .Menumobilelogout > .background > .containerMinHeight {
    min-height: 60px;
  }


  .Menumobilelogout > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Menumobilelogout > .foreground > .elRect {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 59px;
    width: 100.0%;
    height: 1px;
  }


  .Menumobilelogout > .foreground > .elCourbaniza {
    position: absolute;
    left: 30px;
    top: 21px;
    width: 155px;
    height: 21px;
  }

.Menumobilelogout .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Menumobilelogout .elIconButton:focus {
  outline: none;
}



  .Menumobilelogout > .foreground > .elIconButton {
    position: absolute;
    right: 20px;
    top: 6px;
    width: 56px;
    height: 50px;
  }

.Menumobilelogout > .draweroff > .drawerContent {
  right: 0px;
}


/* This component has a fixed-size layout */
  .Draweroff {
    min-height: 114px;  /* Minimum height to ensure layout flow is visible */
    width: 260px; /* This component has a fixed size */
  }
  .Draweroff > .background > .containerMinHeight {
    min-height: 114px;
  }


  .Draweroff > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Draweroff .elSelectordrawer {
}

.Draweroff .elSelectordrawer.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Draweroff > .layoutFlow > .elSelectordrawer {
    position: relative;
    margin-top: 20px;
    width: 230px;
    margin-left: 20px;
  }
  .Draweroff > .layoutFlow > .elSelectordrawer > * { 
  width: 230px;
  }
  

.Draweroff .elOpenlanguageSelectormobile {
}

.Draweroff .elOpenlanguageSelectormobile.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Draweroff > .layoutFlow > .elOpenlanguageSelectormobile {
    position: relative;
    margin-top: 5px;
    width: 100px;
    margin-left: 20px;
  }
  .Draweroff > .layoutFlow > .elOpenlanguageSelectormobile > * { 
  width: 100px;
  }
  


  .Draweroff > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: 20px;
  }
  .Draweroff > .layoutFlow > .elText > * { 
  width: 100px;
  }
  

/* This component has a fixed-size layout */
  .Formpostproject {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Formpostproject > .layoutFlow > .state0_elNewprojecttext1906127225 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state0_elNewprojecttext1906127225 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state0_elCategorytxt762708970 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state0_elCategorytxt762708970 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state0_elIssueCatpickerUs {
    position: relative;
    margin-top: 10px;
    width: 230px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state0_elIssueCatpickerUs > * { 
  width: 230px;
  }
  

.Formpostproject .state0_elNametitlepashCont1340277902 {
}

.Formpostproject .state0_elNametitlepashCont1340277902.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elNametitlepashCont1340277902 {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .Formpostproject > .layoutFlow > .state0_elNametitlepashCont1340277902 > * { 
    width: 100%;
  }
  

.Formpostproject .state0_elQuillEditor1056489721 {
}

.Formpostproject .state0_elQuillEditor1056489721.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elQuillEditor1056489721 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state0_elQuillEditor1056489721 > * { 
    width: 100%;
  }
  


  .Formpostproject > .layoutFlow > .state0_elMyquill1565220484 {
    position: relative;
    margin-top: 0px;
    width: 1px;
    margin-left: -60px;
  }
  .Formpostproject > .layoutFlow > .state0_elMyquill1565220484 > * { 
  width: 1px;
  height: 0px;
  }
  

.Formpostproject .state0_elCompGeo1495699650 {
}

.Formpostproject .state0_elCompGeo1495699650.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elCompGeo1495699650 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state0_elCompGeo1495699650 > * { 
    width: 100%;
  }
  

.Formpostproject .state0_elCoverCont1891114324 {
}

.Formpostproject .state0_elCoverCont1891114324.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elCoverCont1891114324 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state0_elCoverCont1891114324 > * { 
    width: 100%;
  }
  

.Formpostproject .state0_elHeroCont1391499760 {
}

.Formpostproject .state0_elHeroCont1391499760.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elHeroCont1391499760 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state0_elHeroCont1391499760 > * { 
    width: 100%;
  }
  

.Formpostproject .state0_elActiveInactiveTread580153632 {
}

.Formpostproject .state0_elActiveInactiveTread580153632.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state0_elActiveInactiveTread580153632 {
    position: relative;
    margin-top: 25px;
    width: 290px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state0_elActiveInactiveTread580153632 > * { 
  width: 290px;
  }
  

.Formpostproject .state0_elButton419882903 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state0_elButton419882903 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state0_elButton419882903 {
    line-height: 35px;
    position: absolute;
    margin-top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state0_elButton419882903 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 15px;
  height: 35px;
  }
  
  .flowRow_Formpostproject_state0_elButton419882903_419882903 { 
  }
  

.Formpostproject .state0_elButtonCancel1225381004 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state0_elButtonCancel1225381004 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state0_elButtonCancel1225381004 {
    line-height: 35px;
    position: absolute;
    top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state0_elButtonCancel1225381004 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 170px;
  height: 35px;
  }
  


  .Formpostproject > .layoutFlow > .state0_elRectangle1851903361 {
    position: relative;
    margin-top: 116px;
    width: 49px;
    margin-left: 130px;
  }
  .Formpostproject > .layoutFlow > .state0_elRectangle1851903361 > * { 
  width: 49px;
  height: 31px;
  }
  

/* This component has a fixed-size layout */
  .Formpostproject {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Formpostproject > .layoutFlow > .state1_elNewprojecttext1906127225 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state1_elNewprojecttext1906127225 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state1_elCategorytxt762708970 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state1_elCategorytxt762708970 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state1_elIssueCatpickerPtbr {
    position: relative;
    margin-top: 10px;
    width: 230px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state1_elIssueCatpickerPtbr > * { 
  width: 230px;
  }
  

.Formpostproject .state1_elNametitlepashCont1340277902 {
}

.Formpostproject .state1_elNametitlepashCont1340277902.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elNametitlepashCont1340277902 {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .Formpostproject > .layoutFlow > .state1_elNametitlepashCont1340277902 > * { 
    width: 100%;
  }
  

.Formpostproject .state1_elQuillEditor1056489721 {
}

.Formpostproject .state1_elQuillEditor1056489721.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elQuillEditor1056489721 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state1_elQuillEditor1056489721 > * { 
    width: 100%;
  }
  


  .Formpostproject > .layoutFlow > .state1_elMyquill1565220484 {
    position: relative;
    margin-top: 0px;
    width: 1px;
    margin-left: -60px;
  }
  .Formpostproject > .layoutFlow > .state1_elMyquill1565220484 > * { 
  width: 1px;
  height: 0px;
  }
  

.Formpostproject .state1_elCompGeo1495699650 {
}

.Formpostproject .state1_elCompGeo1495699650.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elCompGeo1495699650 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state1_elCompGeo1495699650 > * { 
    width: 100%;
  }
  

.Formpostproject .state1_elCoverCont1891114324 {
}

.Formpostproject .state1_elCoverCont1891114324.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elCoverCont1891114324 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state1_elCoverCont1891114324 > * { 
    width: 100%;
  }
  

.Formpostproject .state1_elHeroCont1391499760 {
}

.Formpostproject .state1_elHeroCont1391499760.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elHeroCont1391499760 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state1_elHeroCont1391499760 > * { 
    width: 100%;
  }
  

.Formpostproject .state1_elActiveInactiveTread580153632 {
}

.Formpostproject .state1_elActiveInactiveTread580153632.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state1_elActiveInactiveTread580153632 {
    position: relative;
    margin-top: 25px;
    width: 290px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state1_elActiveInactiveTread580153632 > * { 
  width: 290px;
  }
  

.Formpostproject .state1_elButton419882903 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state1_elButton419882903 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state1_elButton419882903 {
    line-height: 35px;
    position: absolute;
    margin-top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state1_elButton419882903 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 15px;
  height: 35px;
  }
  
  .flowRow_Formpostproject_state1_elButton419882903_419882903 { 
  }
  

.Formpostproject .state1_elButtonCancel1225381004 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state1_elButtonCancel1225381004 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state1_elButtonCancel1225381004 {
    line-height: 35px;
    position: absolute;
    top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state1_elButtonCancel1225381004 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 170px;
  height: 35px;
  }
  


  .Formpostproject > .layoutFlow > .state1_elRectangle1851903361 {
    position: relative;
    margin-top: 116px;
    width: 49px;
    margin-left: 130px;
  }
  .Formpostproject > .layoutFlow > .state1_elRectangle1851903361 > * { 
  width: 49px;
  height: 31px;
  }
  

/* This component has a fixed-size layout */
  .Formpostproject {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Formpostproject > .layoutFlow > .state2_elNewprojecttext1906127225 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state2_elNewprojecttext1906127225 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state2_elCategorytxt762708970 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 25px;
  }
  .Formpostproject > .layoutFlow > .state2_elCategorytxt762708970 > * { 
  width: 238px;
  }
  


  .Formpostproject > .layoutFlow > .state2_elIssueCatpickeresEs {
    position: relative;
    margin-top: 10px;
    width: 230px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state2_elIssueCatpickeresEs > * { 
  width: 230px;
  }
  

.Formpostproject .state2_elNametitlepashCont1340277902 {
}

.Formpostproject .state2_elNametitlepashCont1340277902.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elNametitlepashCont1340277902 {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    width: calc(100.0% - 30px);
  }
  .Formpostproject > .layoutFlow > .state2_elNametitlepashCont1340277902 > * { 
    width: 100%;
  }
  

.Formpostproject .state2_elQuillEditor1056489721 {
}

.Formpostproject .state2_elQuillEditor1056489721.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elQuillEditor1056489721 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state2_elQuillEditor1056489721 > * { 
    width: 100%;
  }
  


  .Formpostproject > .layoutFlow > .state2_elMyquill1565220484 {
    position: relative;
    margin-top: 0px;
    width: 1px;
    margin-left: -60px;
  }
  .Formpostproject > .layoutFlow > .state2_elMyquill1565220484 > * { 
  width: 1px;
  height: 0px;
  }
  

.Formpostproject .state2_elCompGeo1495699650 {
}

.Formpostproject .state2_elCompGeo1495699650.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elCompGeo1495699650 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state2_elCompGeo1495699650 > * { 
    width: 100%;
  }
  

.Formpostproject .state2_elCoverCont1891114324 {
}

.Formpostproject .state2_elCoverCont1891114324.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elCoverCont1891114324 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state2_elCoverCont1891114324 > * { 
    width: 100%;
  }
  

.Formpostproject .state2_elHeroCont1391499760 {
}

.Formpostproject .state2_elHeroCont1391499760.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elHeroCont1391499760 {
    position: relative;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 10px;
    width: calc(100.0% - 35px);
  }
  .Formpostproject > .layoutFlow > .state2_elHeroCont1391499760 > * { 
    width: 100%;
  }
  

.Formpostproject .state2_elActiveInactiveTread580153632 {
}

.Formpostproject .state2_elActiveInactiveTread580153632.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Formpostproject > .layoutFlow > .state2_elActiveInactiveTread580153632 {
    position: relative;
    margin-top: 25px;
    width: 290px;
    margin-left: 30px;
  }
  .Formpostproject > .layoutFlow > .state2_elActiveInactiveTread580153632 > * { 
  width: 290px;
  }
  

.Formpostproject .state2_elButton419882903 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state2_elButton419882903 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state2_elButton419882903 {
    line-height: 35px;
    position: absolute;
    margin-top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state2_elButton419882903 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 15px;
  height: 35px;
  }
  
  .flowRow_Formpostproject_state2_elButton419882903_419882903 { 
  }
  

.Formpostproject .state2_elButtonCancel1225381004 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Formpostproject .state2_elButtonCancel1225381004 button:focus {
  outline: none;
}



  .Formpostproject > .layoutFlow > .flowRow > .state2_elButtonCancel1225381004 {
    line-height: 35px;
    position: absolute;
    top: 10px;
    width: 100%;
  }
  .Formpostproject > .layoutFlow > .flowRow > .state2_elButtonCancel1225381004 > * { 
  margin-left: auto;
  width: 138px;
  margin-right: 170px;
  height: 35px;
  }
  


  .Formpostproject > .layoutFlow > .state2_elRectangle1851903361 {
    position: relative;
    margin-top: 116px;
    width: 49px;
    margin-left: 130px;
  }
  .Formpostproject > .layoutFlow > .state2_elRectangle1851903361 > * { 
  width: 49px;
  height: 31px;
  }
  

/* This component has a fixed-size layout */
  .Formpostproject {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Formpostproject > .layoutFlow > .state3_elMyquill1565220484 {
    position: relative;
    margin-top: 0px;
    width: 1px;
    margin-left: -60px;
  }
  .Formpostproject > .layoutFlow > .state3_elMyquill1565220484 > * { 
  width: 1px;
  height: 0px;
  }
  

/* This component has a fixed-size layout */
  .Formpostproject {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Formpostproject > .layoutFlow > .state4_elMyquill1565220484 {
    position: relative;
    margin-top: 0px;
    width: 1px;
    margin-left: -60px;
  }
  .Formpostproject > .layoutFlow > .state4_elMyquill1565220484 > * { 
  width: 1px;
  height: 0px;
  }
  

/* This component has a fixed-size layout */
  .Geolocation2 {
    width: 100%; /* This component was designed using a width of 272px */
    height: 100%;
  }


  .Geolocation2 > .background > .state0_elBackground837232966 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 5px;
    width: 100.0%;
    height: calc(100% + 0.0% + -10.0px);
  }


  .Geolocation2 > .layoutFlow > .state0_elText832100114 {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Geolocation2 > .layoutFlow > .state0_elText832100114 > * { 
  width: 238px;
  }
  

.Geolocation2 .state0_elCompmyGeo1785133442 {
}

.Geolocation2 .state0_elCompmyGeo1785133442.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Geolocation2 > .layoutFlow > .state0_elCompmyGeo1785133442 {
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 10px;
    width: calc(100.0% - 15px);
  }
  .Geolocation2 > .layoutFlow > .state0_elCompmyGeo1785133442 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .flowRow > .state0_elTextCopy1532022204 {
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    width: 200px;
    margin-left: 60px;
  }
  .Geolocation2 > .layoutFlow > .flowRow > .state0_elTextCopy1532022204 > * { 
  width: 200px;
  }
  
  .flowRow_Geolocation2_state0_elTextCopy1532022204_1532022204 { 
  }
  


  .Geolocation2 > .layoutFlow > .state0_elMapgeoicon1657176273 {
    position: relative;
    margin-top: 10px;
    width: 28px;
    margin-left: 20px;
  }
  .Geolocation2 > .layoutFlow > .state0_elMapgeoicon1657176273 > * { 
  width: 28px;
  height: 26px;
  }
  


  .Geolocation2 > .layoutFlow > .state0_elText21318623397 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Geolocation2 > .layoutFlow > .state0_elText21318623397 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .state0_elField1816951457 {
    position: relative;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Geolocation2 > .layoutFlow > .state0_elField1816951457 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .state0_elSpacer983853017 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Geolocation2 > .layoutFlow > .state0_elSpacer983853017 > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .Geolocation2 {
    width: 100%; /* This component was designed using a width of 272px */
    height: 100%;
  }


  .Geolocation2 > .background > .state1_elBackground837232966 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 5px;
    width: 100.0%;
    height: calc(100% + 0.0% + -10.0px);
  }


  .Geolocation2 > .layoutFlow > .state1_elText832100114 {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Geolocation2 > .layoutFlow > .state1_elText832100114 > * { 
  width: 238px;
  }
  

.Geolocation2 .state1_elCompmyGeo1785133442 {
}

.Geolocation2 .state1_elCompmyGeo1785133442.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Geolocation2 > .layoutFlow > .state1_elCompmyGeo1785133442 {
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 10px;
    width: calc(100.0% - 15px);
  }
  .Geolocation2 > .layoutFlow > .state1_elCompmyGeo1785133442 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .flowRow > .state1_elTextCopy1532022204 {
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    width: 200px;
    margin-left: 60px;
  }
  .Geolocation2 > .layoutFlow > .flowRow > .state1_elTextCopy1532022204 > * { 
  width: 200px;
  }
  
  .flowRow_Geolocation2_state1_elTextCopy1532022204_1532022204 { 
  }
  


  .Geolocation2 > .layoutFlow > .state1_elMapgeoicon1657176273 {
    position: relative;
    margin-top: 10px;
    width: 28px;
    margin-left: 20px;
  }
  .Geolocation2 > .layoutFlow > .state1_elMapgeoicon1657176273 > * { 
  width: 28px;
  height: 26px;
  }
  


  .Geolocation2 > .layoutFlow > .state1_elText21318623397 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Geolocation2 > .layoutFlow > .state1_elText21318623397 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .state1_elField1816951457 {
    position: relative;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Geolocation2 > .layoutFlow > .state1_elField1816951457 > * { 
    width: 100%;
  }
  


  .Geolocation2 > .layoutFlow > .state1_elSpacer983853017 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Geolocation2 > .layoutFlow > .state1_elSpacer983853017 > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .Activeinactive {
    width: 100%; /* This component was designed using a width of 274px */
    height: 100%;
  }


  .Activeinactive > .layoutFlow > .elStatus {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 159px;
    margin-left: 0px;
  }
  .Activeinactive > .layoutFlow > .elStatus > * { 
  width: 159px;
  }
  


  .Activeinactive > .layoutFlow > .elCheckboxactive {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 10px;
    width: calc(100.0% - 10px);
  }
  .Activeinactive > .layoutFlow > .elCheckboxactive > * { 
    width: 100%;
  }
  


  .Activeinactive > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Activeinactive > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Empty {
    min-height: 114px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .Empty > .background > .containerMinHeight {
    min-height: 114px;
  }

/* This component has a fixed-size layout */
  .MenuWeb {
    width: 100%; /* This component was designed using a width of 220px */
    height: 100%;
  }


  .MenuWeb > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .MenuWeb > .layoutFlow > .elMenu0 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu0 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu1 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu1 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu2 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu3 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu3 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu4 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu4 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu4Copy {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu4Copy > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu4Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu4Copy2 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu4Copy3 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu4Copy3 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu5 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu5 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu5Copy {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu5Copy > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elMenu5Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    width: 180px;
    margin-left: 20px;
  }
  .MenuWeb > .layoutFlow > .elMenu5Copy2 > * { 
  width: 180px;
  }
  


  .MenuWeb > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .MenuWeb > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Uploadcoverphoto {
    min-height: 265px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Uploadcoverphoto > .background > .containerMinHeight {
    min-height: 265px;
  }

@keyframes fadeIn_Uploadcoverphoto_state0_elNoimagecont {
  0% { opacity: 0.0; }
  44% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Uploadcoverphoto > .layoutFlow > .state0_elNoimagecont {
  animation: 0.45s fadeIn_Uploadcoverphoto_state0_elNoimagecont;
}

  .Uploadcoverphoto > .layoutFlow > .state0_elNoimagecont {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Uploadcoverphoto > .layoutFlow > .state0_elNoimagecont > * { 
    width: 100%;
  height: 230px;
  }
  


  .Uploadcoverphoto > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Uploadcoverphoto > .layoutFlow > .state0_elSpacer > * { 
  width: 10px;
  height: 70px;
  }
  


  .Uploadcoverphoto > .foreground > .state0_elProgress369463656 {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 106px;
    width: 48px;
    height: 48px;
  }

.Uploadcoverphoto .state0_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Uploadcoverphoto .state0_elIconButton:focus {
  outline: none;
}



  .Uploadcoverphoto > .foreground > .state0_elIconButton {
    position: absolute;
    right: 25px;
    top: 30px;
    width: 41px;
    height: 36px;
  }

/* This component has a fixed-size layout */
  .Uploadcoverphoto {
    min-height: 265px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Uploadcoverphoto > .background > .containerMinHeight {
    min-height: 265px;
  }


  .Uploadcoverphoto > .layoutFlow > .state1_elNoimagecont {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Uploadcoverphoto > .layoutFlow > .state1_elNoimagecont > * { 
    width: 100%;
  height: 230px;
  }
  


  .Uploadcoverphoto > .layoutFlow > .state1_elFirebaseUpload {
    position: relative;
    margin-top: 20px;
    width: 140px;
    margin-left: 30px;
  }
  .Uploadcoverphoto > .layoutFlow > .state1_elFirebaseUpload > * { 
  width: 140px;
  height: 34px;
  }
  


  .Uploadcoverphoto > .layoutFlow > .state1_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Uploadcoverphoto > .layoutFlow > .state1_elSpacer > * { 
  width: 10px;
  height: 20px;
  }
  


  .Uploadcoverphoto > .foreground > .state1_elProgress369463656 {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 106px;
    width: 48px;
    height: 48px;
  }


  .Uploadcoverphoto > .foreground > .state1_elText {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 200px;
    width: 238px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .CoverphotoText {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .CoverphotoText > .layoutFlow > .elText2 {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .CoverphotoText > .layoutFlow > .elText2 > * { 
  width: 238px;
  }
  


  .CoverphotoText > .layoutFlow > .elText {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .CoverphotoText > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .CoverphotoText > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .CoverphotoText > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .HerophotoText {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .HerophotoText > .layoutFlow > .elAddphoto {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .HerophotoText > .layoutFlow > .elAddphoto > * { 
  width: 238px;
  }
  


  .HerophotoText > .layoutFlow > .elAddphototext {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .HerophotoText > .layoutFlow > .elAddphototext > * { 
    width: 100%;
  }
  


  .HerophotoText > .layoutFlow > .elCreate {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .HerophotoText > .layoutFlow > .elCreate > * { 
  width: 238px;
  }
  


  .HerophotoText > .layoutFlow > .elCreatetext {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .HerophotoText > .layoutFlow > .elCreatetext > * { 
    width: 100%;
  }
  


  .HerophotoText > .layoutFlow > .elTextarea {
    position: relative;
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .HerophotoText > .layoutFlow > .elTextarea > * { 
    width: 100%;
  height: 60px;
  }
  


  .HerophotoText > .layoutFlow > .elTextareaCount {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .HerophotoText > .layoutFlow > .elTextareaCount > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .HerophotoText > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .HerophotoText > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .CoverCont {
    min-height: 232px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 323px */
    height: 100%;
  }
  .CoverCont > .background > .containerMinHeight {
    min-height: 232px;
  }


  .CoverCont > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

  .CoverCont > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .CoverCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .CoverCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .CoverCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



  .CoverCont > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .CoverCont > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .HeroCont {
    min-height: 232px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 323px */
    height: 100%;
  }
  .HeroCont > .background > .containerMinHeight {
    min-height: 232px;
  }


  .HeroCont > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

  .HeroCont > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .HeroCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .HeroCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .HeroCont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



  .HeroCont > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HeroCont > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Uploadherophoto {
    min-height: 327px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Uploadherophoto > .background > .containerMinHeight {
    min-height: 327px;
  }

@keyframes fadeIn_Uploadherophoto_state0_elNoimagecont {
  0% { opacity: 0.0; }
  55% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Uploadherophoto > .layoutFlow > .state0_elNoimagecont {
  animation: 0.55s fadeIn_Uploadherophoto_state0_elNoimagecont;
}

  .Uploadherophoto > .layoutFlow > .state0_elNoimagecont {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Uploadherophoto > .layoutFlow > .state0_elNoimagecont > * { 
    width: 100%;
  height: 230px;
  }
  


  .Uploadherophoto > .layoutFlow > .state0_elSpacer1807895058 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Uploadherophoto > .layoutFlow > .state0_elSpacer1807895058 > * { 
  width: 10px;
  height: 20px;
  }
  


  .Uploadherophoto > .foreground > .state0_elProgress1168337133 {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 106px;
    width: 48px;
    height: 48px;
  }


  .Uploadherophoto > .foreground > .state0_elPhrase1469585931 {
    line-height: 24px;
    position: absolute;
    left: 40px;
    top: 53px;
    width: 220px;
    height: 26px;
  }

.Uploadherophoto .state0_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Uploadherophoto .state0_elIconButton:focus {
  outline: none;
}



  .Uploadherophoto > .foreground > .state0_elIconButton {
    position: absolute;
    right: 25px;
    top: 30px;
    width: 41px;
    height: 36px;
  }

/* This component has a fixed-size layout */
  .Uploadherophoto {
    min-height: 327px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Uploadherophoto > .background > .containerMinHeight {
    min-height: 327px;
  }


  .Uploadherophoto > .layoutFlow > .state1_elNoimagecont {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Uploadherophoto > .layoutFlow > .state1_elNoimagecont > * { 
    width: 100%;
  height: 230px;
  }
  


  .Uploadherophoto > .layoutFlow > .state1_elFirebaseUpload {
    position: relative;
    margin-top: 20px;
    width: 140px;
    margin-left: 30px;
  }
  .Uploadherophoto > .layoutFlow > .state1_elFirebaseUpload > * { 
  width: 140px;
  height: 34px;
  }
  


  .Uploadherophoto > .layoutFlow > .state1_elSpacer1807895058 {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Uploadherophoto > .layoutFlow > .state1_elSpacer1807895058 > * { 
  width: 10px;
  height: 20px;
  }
  


  .Uploadherophoto > .foreground > .state1_elProgress1168337133 {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 106px;
    width: 48px;
    height: 48px;
  }


  .Uploadherophoto > .foreground > .state1_elPhrase1469585931 {
    line-height: 24px;
    position: absolute;
    left: 40px;
    top: 53px;
    width: 220px;
    height: 26px;
  }


  .Uploadherophoto > .foreground > .state1_elText {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 200px;
    width: 238px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .ModelEditonMap {
    min-height: 569px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }
  .ModelEditonMap > .background > .containerMinHeight {
    min-height: 569px;
  }


  .ModelEditonMap > .foreground > .elMapembed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.ModelEditonMap .elButtonset {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ModelEditonMap .elButtonset:focus {
  outline: none;
}


@keyframes fadeIn_ModelEditonMap_elButtonset {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elButtonset {
  animation: 3.25s fadeIn_ModelEditonMap_elButtonset;
}

  .ModelEditonMap > .foreground > .elButtonset {
    line-height: 35px;
    position: absolute;
    right: 70px;
    bottom: 60px;
    width: 160px;
    height: 35px;
  }

.ModelEditonMap .elBgcomp1 {
}

.ModelEditonMap .elBgcomp1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_ModelEditonMap_elBgcomp1 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elBgcomp1 {
  animation: 4.00s fadeIn_ModelEditonMap_elBgcomp1;
}

  .ModelEditonMap > .foreground > .elBgcomp1 {
    position: absolute;
    left: 10px;
    top: 27px;
    width: 300px;
    height: 100px;
  }

.ModelEditonMap .elBgcomp2 {
}

.ModelEditonMap .elBgcomp2.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_ModelEditonMap_elBgcomp2 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elBgcomp2 {
  animation: 4.00s fadeIn_ModelEditonMap_elBgcomp2;
}

  .ModelEditonMap > .foreground > .elBgcomp2 {
    position: absolute;
    left: 10px;
    top: 136px;
    width: 300px;
    height: 50px;
  }

@keyframes fadeIn_ModelEditonMap_elLtlng {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elLtlng {
  animation: 4.00s fadeIn_ModelEditonMap_elLtlng;
}

  .ModelEditonMap > .foreground > .elLtlng {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 34px;
    width: 280px;
    height: 83px;
  }

@keyframes fadeIn_ModelEditonMap_elGuide {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elGuide {
  animation: 4.00s fadeIn_ModelEditonMap_elGuide;
}

  .ModelEditonMap > .foreground > .elGuide {
    overflow: hidden;
    position: absolute;
    left: 17px;
    top: 148px;
    width: 280px;
    height: 35px;
  }

@keyframes fadeIn_ModelEditonMap_elClose {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ModelEditonMap > .foreground > .elClose {
  animation: 3.00s fadeIn_ModelEditonMap_elClose;
}

  .ModelEditonMap > .foreground > .elClose {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
  }

.ModelEditonMap .elLoadinganima {
}

.ModelEditonMap .elLoadinganima.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .ModelEditonMap > .foreground > .elLoadinganima {
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 234px;
    width: 200px;
    height: 200px;
  }

/* This component has a fixed-size layout */
  .Loadinganima {
    min-height: 114px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 200px */
    height: 100%;
  }
  .Loadinganima > .background > .containerMinHeight {
    min-height: 114px;
  }


  .Loadinganima > .layoutFlow > .elPreloader {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Loadinganima > .layoutFlow > .elPreloader > * { 
    width: 100%;
  height: 200px;
  }
  

@keyframes _default_Loadinganima_elText {
  0% { opacity: 0.0; }
  76% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Loadinganima > .foreground > .elText {
  animation: 1.05s _default_Loadinganima_elText;
}

  .Loadinganima > .foreground > .elText {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 0px - 119px);
    top: 40px;
    width: 238px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .ProjectList {
    min-height: 322px;  /* Minimum height to ensure layout flow is visible */
    min-width: 300px; /* This component has a fixed size */
  }
  .ProjectList > .background > .containerMinHeight {
    min-height: 322px;
  }

@keyframes fadeIn_ProjectList_state0_elNoimagecont {
  0% { opacity: 0.0; }
  44% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ProjectList > .layoutFlow > .state0_elNoimagecont {
  animation: 0.45s fadeIn_ProjectList_state0_elNoimagecont;
}

  .ProjectList > .layoutFlow > .state0_elNoimagecont {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .ProjectList > .layoutFlow > .state0_elNoimagecont > * { 
    width: 100%;
  height: 230px;
  }
  


  .ProjectList > .layoutFlow > .state0_elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .ProjectList > .layoutFlow > .state0_elSpacer > * { 
  width: 10px;
  height: 70px;
  }
  


  .ProjectList > .foreground > .state0_elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 106px;
    width: 48px;
    height: 48px;
  }

/* This component has a fixed-size layout */
  .ProjectList {
    min-height: 322px;  /* Minimum height to ensure layout flow is visible */
    min-width: 300px; /* This component has a fixed size */
  }
  .ProjectList > .background > .containerMinHeight {
    min-height: 322px;
  }

/* This component has a fixed-size layout */
  .Phase {
    min-height: 280px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Phase > .background > .containerMinHeight {
    min-height: 280px;
  }


  .Phase > .background > .elBackground {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    width: calc(100.0% - 5px - 5px);
    height: calc(100% + 0.0% + -10.0px);
  }


  .Phase > .layoutFlow > .elAddphoto {
    line-height: 18px;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Phase > .layoutFlow > .elAddphoto > * { 
  width: 238px;
  }
  


  .Phase > .layoutFlow > .elAddphototext {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Phase > .layoutFlow > .elAddphototext > * { 
    width: 100%;
  }
  


  .Phase > .layoutFlow > .elField {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Phase > .layoutFlow > .elField > * { 
    width: 100%;
  }
  


.Phase > .layoutFlow > .elTextareabriefdescrip textarea {
  height: 100%;
}

  .Phase > .layoutFlow > .elTextareabriefdescrip {
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Phase > .layoutFlow > .elTextareabriefdescrip > * { 
    width: 100%;
  height: 44px;
  }
  


  .Phase > .layoutFlow > .elTextareaCount {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Phase > .layoutFlow > .elTextareaCount > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .Phase > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Phase > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Nametitle {
    min-height: 330px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Nametitle > .background > .containerMinHeight {
    min-height: 330px;
  }


  .Nametitle > .background > .elBackground {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    width: calc(100.0% - 5px - 5px);
    height: calc(100% + 0.0% + -10.0px);
  }


  .Nametitle > .layoutFlow > .elNametext {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: 20px;
  }
  .Nametitle > .layoutFlow > .elNametext > * { 
  width: 145px;
  }
  


  .Nametitle > .layoutFlow > .flowRow > .elNameforaddress {
    overflow: hidden;
    position: absolute;
    margin-top: 10px;
    width: 121px;
    margin-left: 168px;
  }
  .Nametitle > .layoutFlow > .flowRow > .elNameforaddress > * { 
  width: 121px;
  }
  
  .flowRow_Nametitle_elNameforaddress_584504276 { 
  }
  


  .Nametitle > .layoutFlow > .elProjectaddress {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Nametitle > .layoutFlow > .elProjectaddress > * { 
    width: 100%;
  }
  


  .Nametitle > .layoutFlow > .elProjectName {
    position: relative;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Nametitle > .layoutFlow > .elProjectName > * { 
    width: 100%;
  }
  


  .Nametitle > .layoutFlow > .elTitletxt {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20px;
  }
  .Nametitle > .layoutFlow > .elTitletxt > * { 
  width: 238px;
  }
  


  .Nametitle > .layoutFlow > .elProjectTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Nametitle > .layoutFlow > .elProjectTitle > * { 
    width: 100%;
  }
  


  .Nametitle > .layoutFlow > .elSummarytext {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20px;
  }
  .Nametitle > .layoutFlow > .elSummarytext > * { 
  width: 238px;
  }
  


.Nametitle > .layoutFlow > .elSummary textarea {
  height: 100%;
}

  .Nametitle > .layoutFlow > .elSummary {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Nametitle > .layoutFlow > .elSummary > * { 
    width: 100%;
  height: 40px;
  }
  


  .Nametitle > .layoutFlow > .elTextareaCount {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Nametitle > .layoutFlow > .elTextareaCount > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .Nametitle > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Nametitle > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .Nametitlephasecont {
    width: 100%; /* This component was designed using a width of 323px */
    height: 100%;
  }

  .Nametitlephasecont > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col0 {
    flex: 60.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 14.9px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col1 {
    flex: 40.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 14.9px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .Nametitlephasecont > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 20.0px;
    width: 0px;
    min-height: auto;
  }

}



  .Nametitlephasecont > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Nametitlephasecont > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Comp7 {
    min-width: 293px; /* This component has a fixed size */
  }


  .Comp7 > .layoutFlow > .state0_elProjectDescrition793006695 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 0px;
  }
  .Comp7 > .layoutFlow > .state0_elProjectDescrition793006695 > * { 
  width: 238px;
  }
  


  .Comp7 > .layoutFlow > .state0_elEmbed {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Comp7 > .layoutFlow > .state0_elEmbed > * { 
    width: 100%;
  }
  


  .Comp7 > .layoutFlow > .state0_elTextareaCount1442216337 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state0_elTextareaCount1442216337 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 0px;
  }
  


  .Comp7 > .layoutFlow > .state0_elRemainingchars1993978576 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state0_elRemainingchars1993978576 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 0px;
  }
  

/* This component has a fixed-size layout */
  .Comp7 {
    min-width: 293px; /* This component has a fixed size */
  }


  .Comp7 > .layoutFlow > .state1_elProjectDescrition793006695 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 0px;
  }
  .Comp7 > .layoutFlow > .state1_elProjectDescrition793006695 > * { 
  width: 238px;
  }
  


  .Comp7 > .layoutFlow > .state1_elTextareaCount1442216337 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state1_elTextareaCount1442216337 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 0px;
  }
  


  .Comp7 > .layoutFlow > .state1_elRemainingchars1993978576 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state1_elRemainingchars1993978576 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 0px;
  }
  

/* This component has a fixed-size layout */
  .Comp7 {
    min-width: 293px; /* This component has a fixed size */
  }


  .Comp7 > .layoutFlow > .state2_elProjectDescrition793006695 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 0px;
  }
  .Comp7 > .layoutFlow > .state2_elProjectDescrition793006695 > * { 
  width: 238px;
  }
  


  .Comp7 > .layoutFlow > .state2_elTextareaCount1442216337 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state2_elTextareaCount1442216337 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 0px;
  }
  


  .Comp7 > .layoutFlow > .state2_elRemainingchars1993978576 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Comp7 > .layoutFlow > .state2_elRemainingchars1993978576 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 0px;
  }
  

/* This component has a fixed-size layout */
  .Editor {
    width: 100%; /* This component was designed using a width of 293px */
    height: 100%;
  }


  .Editor > .layoutFlow > .state0_elProjectDescrition880152582 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Editor > .layoutFlow > .state0_elProjectDescrition880152582 > * { 
  width: 238px;
  }
  


  .Editor > .layoutFlow > .state0_elEmbed341566042 {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Editor > .layoutFlow > .state0_elEmbed341566042 > * { 
    width: 100%;
  }
  


  .Editor > .layoutFlow > .state0_elTextareaCount686172016 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state0_elTextareaCount686172016 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state0_elRemainingchars1300397438 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state0_elRemainingchars1300397438 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state0_elSpacer1261194532 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Editor > .layoutFlow > .state0_elSpacer1261194532 > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .Editor {
    width: 100%; /* This component was designed using a width of 293px */
    height: 100%;
  }


  .Editor > .layoutFlow > .state1_elProjectDescrition880152582 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Editor > .layoutFlow > .state1_elProjectDescrition880152582 > * { 
  width: 238px;
  }
  


  .Editor > .layoutFlow > .state1_elEmbed341566042 {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Editor > .layoutFlow > .state1_elEmbed341566042 > * { 
    width: 100%;
  }
  


  .Editor > .layoutFlow > .state1_elTextareaCount686172016 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state1_elTextareaCount686172016 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state1_elRemainingchars1300397438 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state1_elRemainingchars1300397438 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state1_elSpacer1261194532 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Editor > .layoutFlow > .state1_elSpacer1261194532 > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .Editor {
    width: 100%; /* This component was designed using a width of 293px */
    height: 100%;
  }


  .Editor > .layoutFlow > .state2_elProjectDescrition880152582 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .Editor > .layoutFlow > .state2_elProjectDescrition880152582 > * { 
  width: 238px;
  }
  


  .Editor > .layoutFlow > .state2_elEmbed341566042 {
    position: relative;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100.0% - 40px);
  }
  .Editor > .layoutFlow > .state2_elEmbed341566042 > * { 
    width: 100%;
  }
  


  .Editor > .layoutFlow > .state2_elTextareaCount686172016 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state2_elTextareaCount686172016 > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state2_elRemainingchars1300397438 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Editor > .layoutFlow > .state2_elRemainingchars1300397438 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 20px;
  }
  


  .Editor > .layoutFlow > .state2_elSpacer1261194532 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Editor > .layoutFlow > .state2_elSpacer1261194532 > * { 
  width: 10px;
  height: 20px;
  }
  

/* This component has a fixed-size layout */
  .ProjectslisComp {
    min-height: 250px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .ProjectslisComp > .background > .containerMinHeight {
    min-height: 250px;
  }


  .ProjectslisComp > .layoutFlow > .elNewprojecttextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 20px;
  }
  .ProjectslisComp > .layoutFlow > .elNewprojecttextCopy > * { 
  width: 238px;
  }
  

.ProjectslisComp > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 10px;
  grid-row-gap: 10px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.ProjectslisComp > .layoutFlow > .elList > div > * {
  display: inline-block;
  min-width: 281px;
  position: relative;
}

  @media (min-width: 1024px) {
    .ProjectslisComp > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .ProjectslisComp > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .ProjectslisComp > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .ProjectslisComp > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 10px;
      grid-row-gap: 10px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


  .ProjectslisComp > .layoutFlow > .elList {
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .ProjectslisComp > .layoutFlow > .elList > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ListProjectsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ListProjectsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ListProjectsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.ListProjectsScreen .elProjectslisComp {
}

.ListProjectsScreen .elProjectslisComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp {
    position: relative;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > * { 
    width: 100%;
  }
  
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > .background > .containerMinHeight {
    min-height: 250px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp {
    position: relative;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > * { 
    width: 100%;
  }
  
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > .background > .containerMinHeight {
    min-height: 250px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp {
    position: relative;
    margin-top: 60px;
    margin-left: 245px;
    margin-right: 160px;
    width: calc(100.0% - 405px);
  }
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > * { 
    width: 100%;
  }
  
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > .background > .containerMinHeight {
    min-height: 249px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp {
    position: relative;
    margin-top: 60px;
    margin-left: 245px;
    margin-right: 280px;
    width: calc(100.0% - 525px);
  }
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > * { 
    width: 100%;
  }
  
  .ListProjectsScreen > .layoutFlow > .elProjectslisComp > .background > .containerMinHeight {
    min-height: 249px;
  }

}

.ListProjectsScreen .elCompnafooter {
  pointer-events: auto;
}

.ListProjectsScreen .elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    position: absolute;
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 500px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 430px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elRect {
    position: absolute;
    left: -50px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elRect {
    left: 29px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}

.ListProjectsScreen .elOpenlanguageSelector {
}

.ListProjectsScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}

.ListProjectsScreen .elModelEditonMap {
}

.ListProjectsScreen .elModelEditonMap.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

@media (min-width: 568px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 768px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 1024px) {
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ListProjectsScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}

/* This component has a fixed-size layout */
  .CardProjectEmpty {
    min-height: 380px;  /* Minimum height to ensure layout flow is visible */
    min-width: 300px; /* This component has a fixed size */
  }
  .CardProjectEmpty > .background > .containerMinHeight {
    min-height: 380px;
  }

@keyframes fadeIn_CardProjectEmpty_elBackground {
  0% { opacity: 0.0; }
  58% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CardProjectEmpty > .background > .elBackground {
  animation: 2.40s fadeIn_CardProjectEmpty_elBackground;
}

  .CardProjectEmpty > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

@keyframes fadeIn_CardProjectEmpty_elCoverimage {
  0% { opacity: 0.0; }
  57% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CardProjectEmpty > .layoutFlow > .elCoverimage {
  animation: 2.30s fadeIn_CardProjectEmpty_elCoverimage;
}

  .CardProjectEmpty > .layoutFlow > .elCoverimage {
    position: relative;
    margin-top: 73px;
    width: 180px;
    margin-left: calc(50.0% - 90px);
  }
  .CardProjectEmpty > .layoutFlow > .elCoverimage > * { 
  width: 180px;
  }
  


  .CardProjectEmpty > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 90px;
  }
  .CardProjectEmpty > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .SingleProject {
    min-height: 1314px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .SingleProject > .background > .containerMinHeight {
    min-height: 1314px;
  }

.SingleProject .elHeroHearderProject {
}

.SingleProject .elHeroHearderProject.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_SingleProject_elHeroHearderProject {
  0% { opacity: 0.0; }
  29% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.SingleProject > .layoutFlow > .elHeroHearderProject {
  animation: 1.40s fadeIn_SingleProject_elHeroHearderProject;
}

  .SingleProject > .layoutFlow > .elHeroHearderProject {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SingleProject > .layoutFlow > .elHeroHearderProject > * { 
    width: 100%;
  }
  

  .SingleProject > .layoutFlow > .elColumns > div {
    display: flex;
    flex-direction: row;
    height: auto;  /* This element is in scroll flow */
    flex-wrap: wrap;
  }

  .SingleProject > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col0 {
    flex: 70.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col0 {
    flex: 70.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col0 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

  .SingleProject > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Base weight for this column */
    position: relative;
  }

@media (min-width: 1024px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col1 {
    flex: 30.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 1023px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col1 {
    flex: 30.0;  /* Column width for this screen format */
    flex-basis: 0;  /* Ignore content size so that column widths will determine size */
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 767px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 767px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}

@media (max-width: 567px) {
  .SingleProject > .layoutFlow > .elColumns > div > .col1 {
    flex: 50.0;  /* Column width for this screen format */
    flex-basis: 567px;
    margin-left: 0.0px;
    margin-top: 0.0px;
    width: 0px;
    min-height: auto;
  }

}



  .SingleProject > .layoutFlow > .elColumns {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SingleProject > .layoutFlow > .elColumns > * { 
    width: 100%;
  }
  


  .SingleProject > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 90px;
  }
  .SingleProject > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  


/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ProjectScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.ProjectScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ProjectScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ProjectScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 70px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ProjectScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ProjectScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 70px;
    margin-left: 250px;
    margin-right: 20px;
    width: calc(100.0% - 270px);
  }
  .ProjectScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ProjectScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: 70px;
    margin-left: 250px;
    margin-right: 20px;
    width: calc(100.0% - 270px);
  }
  .ProjectScreen > .layoutFlow > .elList > * { 
    width: 100%;
  }
  
}

.ProjectScreen .elCompnafooter {
  pointer-events: auto;
}

.ProjectScreen .elCompnafooter.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 71px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter {
    left: 0px;
    right: 0px;
    bottom: -200px;
    width: 100.0%;
    height: 71px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompnafooter > .background > .containerMinHeight {
    min-height: 71px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    position: absolute;
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 222px;
    height: 433px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 114px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 500px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar {
    left: 20px;
    top: 80px;
    width: 220px;
    height: 430px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapperSidebar > .background > .containerMinHeight {
    min-height: 113px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 60px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elSwapper > .background > .containerMinHeight {
    min-height: 60px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elRect {
    position: absolute;
    left: -50px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 29px;
    top: 114px;
    width: 1px;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elRect {
    left: 236px;
    top: 141px;
    width: 10px;
    height: 10px;
  }
}

.ProjectScreen .elOpenlanguageSelector {
}

.ProjectScreen .elOpenlanguageSelector.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    position: fixed;
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 100px;
    height: 70px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector {
    right: 95px;
    top: 60px;
    width: 99px;
    height: 70px;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elOpenlanguageSelector > .background > .containerMinHeight {
    min-height: 70px;
  }

}

.ProjectScreen .elModelEditonMap {
}

.ProjectScreen .elModelEditonMap.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elModelEditonMap > .background > .containerMinHeight {
    min-height: 548px;
  }

}

.ProjectScreen .elCompbg {
}

.ProjectScreen .elCompbg.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: calc(100% + 0.0% + -1.0px);
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

@media (min-width: 568px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 768px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}
@media (min-width: 1024px) {
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg {
    left: 0px;
    right: 0px;
    top: 1px;
    width: 100.0%;
    height: 100%;
  }
  .ProjectScreen > .screenFgContainer > .foreground > .elCompbg > .background > .containerMinHeight {
    min-height: 547px;
  }

}

/* This component has a fixed-size layout */
  .ProjectItem {
    min-height: 340px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .ProjectItem > .background > .containerMinHeight {
    min-height: 340px;
  }


  .ProjectItem > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }


  .ProjectItem > .layoutFlow > .elTitle {
    line-height: 24px;
    position: relative;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .ProjectItem > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .ProjectItem > .layoutFlow > .elDescription {
    line-height: 16px;
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .ProjectItem > .layoutFlow > .elDescription > * { 
    width: 100%;
  }
  

@keyframes fadeIn_ProjectItem_elCoverimage {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ProjectItem > .layoutFlow > .elCoverimage {
  animation: 0.75s fadeIn_ProjectItem_elCoverimage;
}

  .ProjectItem > .layoutFlow > .elCoverimage {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .ProjectItem > .layoutFlow > .elCoverimage > * { 
    width: 100%;
  height: 168px;
  }
  


  .ProjectItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 90px;
  }
  .ProjectItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .PlistItem {
    min-height: 380px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .PlistItem > .background > .containerMinHeight {
    min-height: 380px;
  }


  .PlistItem > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }


  .PlistItem > .layoutFlow > .elNewprojecttext {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .PlistItem > .layoutFlow > .elNewprojecttext > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .elNeighbourhood {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 30px;
  }
  .PlistItem > .layoutFlow > .elNeighbourhood > * { 
  width: 238px;
  }
  


  .PlistItem > .layoutFlow > .elSummary {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .elSummary > * { 
    width: 100%;
  }
  

@keyframes fadeIn_PlistItem_elCoverimage {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.PlistItem > .layoutFlow > .elCoverimage {
  animation: 0.75s fadeIn_PlistItem_elCoverimage;
}

  .PlistItem > .layoutFlow > .elCoverimage {
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .elCoverimage > * { 
    width: 100%;
  height: 168px;
  }
  

@keyframes fadeIn_PlistItem_elHeroimage {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.PlistItem > .layoutFlow > .flowRow > .elHeroimage {
  animation: 0.75s fadeIn_PlistItem_elHeroimage;
}

  .PlistItem > .layoutFlow > .flowRow > .elHeroimage {
    position: absolute;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elHeroimage > * { 
    width: 100%;
  height: 168px;
  }
  
  .flowRow_PlistItem_elHeroimage_1214261014 { 
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elDescription {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elProjectTitle {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elProjectTitle > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elAddress {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elAddress > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elPhase {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elPhase > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elPhaseEnd {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elPhaseEnd > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .flowRow > .elPhaseDescription {
    line-height: 16px;
    position: absolute;
    top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .PlistItem > .layoutFlow > .flowRow > .elPhaseDescription > * { 
    width: 100%;
  }
  


  .PlistItem > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 90px;
  }
  .PlistItem > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .ProjectLisItem2 {
    min-height: 338px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 281px */
    height: 100%;
  }
  .ProjectLisItem2 > .background > .containerMinHeight {
    min-height: 338px;
  }

.ProjectLisItem2 .elCardProject {
}

.ProjectLisItem2 .elCardProject.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .ProjectLisItem2 > .layoutFlow > .elCardProject {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ProjectLisItem2 > .layoutFlow > .elCardProject > * { 
    width: 100%;
  }
  

.ProjectLisItem2 .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .ProjectLisItem2 > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Bgogin {
    min-height: 280px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 300px; /* This component has a fixed size */
  }
  .Bgogin > .background > .containerMinHeight {
    min-height: 280px;
  }


  .Bgogin > .background > .elBackground {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    width: calc(100.0% - 5px - 5px);
    height: calc(100% + 0.0% + -10.0px);
  }

/* This component has a fixed-size layout */
  .CompMap {
    min-height: 605px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 322px */
    height: 100%;
  }
  .CompMap > .background > .containerMinHeight {
    min-height: 605px;
  }


  .CompMap > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .CompMap > .background > .elMapembed {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.CompMap .elBgcomp1 {
}

.CompMap .elBgcomp1.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}


@keyframes fadeIn_CompMap_elBgcomp1 {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMap > .layoutFlow > .flowRow > .elBgcomp1 {
  animation: 4.00s fadeIn_CompMap_elBgcomp1;
}

  .CompMap > .layoutFlow > .flowRow > .elBgcomp1 {
    position: absolute;
    margin-top: 20px;
    width: 211px;
    margin-left: 10px;
  }
  .CompMap > .layoutFlow > .flowRow > .elBgcomp1 > * { 
  width: 211px;
  }
  
  .flowRow_CompMap_elBgcomp1_1285671764 { 
  }
  

.CompMap .elButton2 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CompMap .elButton2 button:focus {
  outline: none;
}


@keyframes fadeIn_CompMap_elButton2 {
  0% { opacity: 0.0; }
  92% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMap > .layoutFlow > .flowRow > .elButton2 {
  animation: 3.25s fadeIn_CompMap_elButton2;
}

  .CompMap > .layoutFlow > .flowRow > .elButton2 {
    line-height: 35px;
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .CompMap > .layoutFlow > .flowRow > .elButton2 > * { 
  margin-left: auto;
  width: 160px;
  margin-right: 20px;
  height: 35px;
  }
  

@keyframes fadeIn_CompMap_elLtlng {
  0% { opacity: 0.0; }
  75% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.CompMap > .layoutFlow > .elLtlng {
  animation: 4.00s fadeIn_CompMap_elLtlng;
}

  .CompMap > .layoutFlow > .elLtlng {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 151px;
    margin-left: 21px;
  }
  .CompMap > .layoutFlow > .elLtlng > * { 
  width: 151px;
  }
  

.CompMap .elLoadinganima {
}

.CompMap .elLoadinganima.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .CompMap > .foreground > .elLoadinganima {
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 150px;
    width: 200px;
    height: 200px;
  }

/* This component has a fixed-size layout */
  .ProjectItemb2 {
    min-height: 280px;  /* Minimum height to ensure layout flow is visible */
    min-width: 320px; /* This component has a fixed size */
  }
  .ProjectItemb2 > .background > .containerMinHeight {
    min-height: 280px;
  }


  .ProjectItemb2 > .layoutFlow > .elProjecttitle {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 238px;
    margin-left: 26px;
  }
  .ProjectItemb2 > .layoutFlow > .elProjecttitle > * { 
  width: 238px;
  }
  


  .ProjectItemb2 > .layoutFlow > .elAddress {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    margin-left: 26px;
    margin-right: -20px;
    width: calc(100.0% - 6px);
  }
  .ProjectItemb2 > .layoutFlow > .elAddress > * { 
    width: 100%;
  }
  


  .ProjectItemb2 > .layoutFlow > .elPhase {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 238px;
    margin-left: 26px;
  }
  .ProjectItemb2 > .layoutFlow > .elPhase > * { 
  width: 238px;
  }
  


  .ProjectItemb2 > .layoutFlow > .elProjectURL {
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    width: 238px;
    margin-left: 26px;
  }
  .ProjectItemb2 > .layoutFlow > .elProjectURL > * { 
  width: 238px;
  }
  


  .ProjectItemb2 > .foreground > .elMomentJS {
    position: absolute;
    left: -1px;
    top: -20px;
    width: 23px;
    height: 23px;
  }

/* This component has a fixed-size layout */
  .HeroHearderProject {
    min-height: 240px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .HeroHearderProject > .background > .containerMinHeight {
    min-height: 240px;
  }


  .HeroHearderProject > .background > .elNoimagecontn {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }


  .HeroHearderProject > .layoutFlow > .elText {
    line-height: 33px;
    position: relative;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .HeroHearderProject > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .HeroHearderProject > .foreground > .elPin {
    position: absolute;
    left: 30px;
    bottom: 38px;
    width: 12px;
    height: 16px;
  }


  .HeroHearderProject > .foreground > .elNeighbourhood {
    overflow: hidden;
    position: absolute;
    left: 44px;
    bottom: 34px;
    width: 238px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .ProjectItemb1 {
    min-height: 621px;  /* Minimum height to ensure layout flow is visible */
    min-width: 300px; /* This component has a fixed size */
  }
  .ProjectItemb1 > .background > .containerMinHeight {
    min-height: 621px;
  }


  .ProjectItemb1 > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }


  .ProjectItemb1 > .layoutFlow > .elProjectName {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 10px;
    width: calc(100.0% - 40px);
  }
  .ProjectItemb1 > .layoutFlow > .elProjectName > * { 
    width: 100%;
  }
  


  .ProjectItemb1 > .layoutFlow > .elNeighbourhood {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 30px;
  }
  .ProjectItemb1 > .layoutFlow > .elNeighbourhood > * { 
  width: 238px;
  }
  


  .ProjectItemb1 > .layoutFlow > .elSummary {
    line-height: 16px;
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .ProjectItemb1 > .layoutFlow > .elSummary > * { 
    width: 100%;
  }
  

@keyframes fadeIn_ProjectItemb1_elCoverimage {
  0% { opacity: 0.0; }
  67% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.ProjectItemb1 > .layoutFlow > .elCoverimage {
  animation: 0.75s fadeIn_ProjectItemb1_elCoverimage;
}

  .ProjectItemb1 > .layoutFlow > .elCoverimage {
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100.0% - 60px);
  }
  .ProjectItemb1 > .layoutFlow > .elCoverimage > * { 
    width: 100%;
  height: 168px;
  }
  


  .ProjectItemb1 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 90px;
  }
  .ProjectItemb1 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 30px;
  }
  

/* This component has a fixed-size layout */
  .ProjectItem2 {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .ProjectItem2 > .background > .elBackground {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }


  .ProjectItem2 > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: 30px;
  }
  .ProjectItem2 > .layoutFlow > .elText > * { 
  width: 238px;
  }
  


  .ProjectItem2 > .layoutFlow > .elPhase {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .ProjectItem2 > .layoutFlow > .elPhase > * { 
    width: 100%;
  }
  


  .ProjectItem2 > .layoutFlow > .elPhaseDescription {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .ProjectItem2 > .layoutFlow > .elPhaseDescription > * { 
    width: 100%;
  }
  


  .ProjectItem2 > .layoutFlow > .elTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 30px;
  }
  .ProjectItem2 > .layoutFlow > .elTextCopy > * { 
  width: 238px;
  }
  


  .ProjectItem2 > .layoutFlow > .elTimetoend {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 20px;
    width: calc(100.0% - 50px);
  }
  .ProjectItem2 > .layoutFlow > .elTimetoend > * { 
    width: 100%;
  }
  


  .ProjectItem2 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 67px;
  }
  .ProjectItem2 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 20px;
  }
  


  .ProjectItem2 > .foreground > .elMomentJS {
    position: absolute;
    left: -16px;
    top: -35px;
    width: 23px;
    height: 23px;
  }

/* This component has a fixed-size layout */
  .Spot7 {
    min-width: 320px; /* This component has a fixed size */
  }


  .Spot7 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: -2px;
    margin-right: 0px;
    width: calc(100.0% - -2px);
  }
  .Spot7 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Spot8 {
    min-width: 320px; /* This component has a fixed size */
  }


  .Spot8 > .layoutFlow > .elText2 {
    line-height: 16px;
    position: relative;
    margin-top: 5px;
    margin-left: -2px;
    margin-right: 0px;
    width: calc(100.0% - -2px);
  }
  .Spot8 > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  

